const _ = require('lodash')
import {cookieUtils} from 'santa-core-utils'

const LANGUAGE_COOKIE = 'wixLanguage'

function getLanguageFromCookie(cookieStr) {
    return cookieUtils.parseCookieString(cookieStr)[LANGUAGE_COOKIE]
}

function getLanguageFromBrowser() {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return null
    }

    const lang = window.navigator.languages ? window.navigator.languages[0] : null
    return lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage
}
const languages = [
    'de',
    'en',
    'es',
    'fr',
    'it',
    'ja',
    'ko',
    'pl',
    'ru',
    'nl',
    'tr',
    'sv',
    'pt',
    'no',
    'da',
    'hi',
    'zh',
    'cs',
    'th'
]



const getLanguage = (cookieStr, parsedUrl, langFromModel) => { // eslint-disable-line complexity
    const fromCookie = getLanguageFromCookie(cookieStr || '')
    const fromUrl = parsedUrl.query && parsedUrl.query.lang
    const fromDomain = () => {
        const parts = parsedUrl.host.split('.')
        return parts[0].length === 2 ? parts[0] : null
    }

    let lng = fromUrl || fromDomain() || fromCookie || getLanguageFromBrowser() || langFromModel
    if (lng) {
        lng = lng.substring(0, 2).toLowerCase()
    }

    return _.includes(languages, lng) ? lng : 'en'
}



const FACEBOOK_SDK_VERSION = 'v2.4'
const FB_APP_ID = '304553036307597'

const facebookSDKTemplate = _.template('//connect.facebook.net/<%= lang %>/sdk.js#xfbml=1&appId=<%= appId %>&version=<%= sdkVersion %>') // eslint-disable-line @wix/santa/no-module-state

function getFacebookSdkLanguage(context) {
    const languageDecode = {
        en: 'en_US',
        es: 'es_ES',
        pt: 'pt_BR',
        ru: 'ru_RU',
        fr: 'fr_FR',
        de: 'de_DE',
        ja: 'ja_JP',
        ko: 'ko_KR',
        it: 'it_IT',
        pl: 'pl_PL',
        tr: 'tr_TR',
        nl: 'nl_NL',
        sv: 'sv_SE',
        da: 'da_DK',
        no: 'nn_NO'
    }

    return languageDecode[getLanguage(context.cookie, context.currentUrl, context.userLanguage)] || languageDecode.en
}

function getScriptDescription(scriptName, context) { //eslint-disable-line
    if (scriptName === 'FACEBOOK') {
        return {
            NAME: 'FacebookSDK',
            SRC: facebookSDKTemplate({lang: getFacebookSdkLanguage(context), sdkVersion: FACEBOOK_SDK_VERSION, appId: FB_APP_ID})
        }
    }
    if (scriptName === 'GOOGLE') {
        return {
            NAME: 'GoogleApi',
            SRC: '//apis.google.com/js/plusone.js',
            actionBefore() {
                window.___gcfg = {
                    lang: getLanguage(context.cookie, context.currentUrl, context.userLanguage)
                }
            }
        }
    }
    if (scriptName === 'LINE') {
        return {
            NAME: 'LineApi',
            SRC: 'https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js'
        }
    }
}

module.exports = {
    getScriptDescription
}
