import {withActions} from 'carmi-host-extensions'
import {boltPageHOC} from './boltPageHOC'
import _ from 'lodash'

export const name = 'PageAspect'
export const PAGE_COMP_TYPE = 'mobile.core.components.Page'

export const defaultModel = {
    isPageClassReplaced: false,
    handledPages: {},
    pageKeys: {}
}

export const functionLibrary = {
    syncSOSP: (updateActiveModes, getSOSPModes, activeSOSPModeId, correctSOSPModeId) => {
        if (activeSOSPModeId === correctSOSPModeId) {
            return true
        }
        if (!correctSOSPModeId) {
            updateActiveModes(activeSOSPModeId, undefined)
        } else {
            updateActiveModes(correctSOSPModeId, getSOSPModes()[correctSOSPModeId])
        }
        return false
    },
    isPageRenderedForViewMode: (pageRef, isMobileView) => 
        _.get(pageRef, 'props.isMobileView') === isMobileView,
    addBoltPageToCompClasses: withActions(({updateCompClass, setPageClassReplaced}, pageComponent) => {
        updateCompClass(PAGE_COMP_TYPE, boltPageHOC(pageComponent))
        setPageClassReplaced(true)
    })
}
