import _ from 'lodash'
import {blogAppPartNames, urlUtils, siteConstants} from 'santa-core-utils'
import {CLASSICS_EVENTS} from '../../../utils/appPartBiConstants'
import {queryBlogCategories, extendParamsWithBlogCategoryFilter} from '../../../utils/blog/blogCategories'
import * as mediaPostConverter from '../../../utils/blog/mediaPostConverter'
import {getDescriptorRequest} from '../../../utils/descriptorUtils'
import {getSiteDataDestination, getDataByCompId} from '../../../utils/wixappsDataHandler'
import {handleVimeoVideoThumbUrls, getVideoItems} from '../../../utils/blog/videoThumbDataHandler'
import transformAndSetMetaData from '../../../utils/transformAndSetMetaData'
import transformMediaItemsResponse from '../../../utils/transformMediaItemsResponse'
import {readRelatedPosts} from '../../../utils/blog/relatedPosts'

const getNumberOfPostsPerPage = (compData, format, defaultPageSize) => {
    const predicate = {
        fieldId: 'vars',
        key: 'itemsPerPage',
        view: compData.viewName
    }

    if (!_.isUndefined(format)) {
        predicate.format = format
    }

    return _.result(_.find(compData.appLogicCustomizations, predicate), 'value', defaultPageSize || 10)
}

const appSpecificFilterMap = {
    Posts: {
        query: {
            'scheduled.iso': {
                $not: {
                    $gt: '$now'
                }
            },
            deleted: {$ne: true}
        },
        groupByAndCount: {
            'scheduled.iso': {
                $not: {
                    $gt: '$now'
                }
            },
            draft: false,
            deleted: {$ne: true}
        }
    }
}
const partsQueryMap = {}

/*********************** Blog *****************************/
partsQueryMap[blogAppPartNames.ARCHIVE] = {
    method: archiveGroupByAndCount,
    defaultOptions: {
        collectionId: 'Posts',
        type: 'Post',
        field: 'date.iso',
        project: {
            date: {
                iso: {
                    $substr: [
                        '$date.iso',
                        0,
                        7
                    ]
                }
            }
        },
        filter: {
            draft: false
        },
        sort: 'byKeyDesc',
        normalizeTo: 5,
        limit: null
    }
}

partsQueryMap[blogAppPartNames.CUSTOM_FEED] = {
    method: queryAndHandleMediaPostWithUrllessPagination,
    defaultOptions: {
        collectionId: 'Posts',
        limit: '',
        sort: {'date.iso': -1},
        filterView: 'filter',
        filterType: 'PostFilter',
        resultType: 'Post',
        fields: ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'author', 'categoryIds', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
    }
}

partsQueryMap[blogAppPartNames.POSTS_GALLERY] = {
    method: queryAndHandleMediaPost,
    defaultOptions: {
        collectionId: 'Posts',
        limit: 30,
        sort: {'date.iso': -1},
        filterView: 'filter',
        filterType: 'PostFilter',
        resultType: 'Post',
        fields: ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
    }
}

partsQueryMap[blogAppPartNames.TICKER] = {
    method: queryAndHandleMediaPost,
    defaultOptions: {
        collectionId: 'Posts',
        limit: 10,
        sort: {'date.iso': -1},
        filterView: 'filter',
        filterType: 'PostFilter',
        resultType: 'Post',
        fields: ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
    }
}

partsQueryMap[blogAppPartNames.FEATURED_POSTS] = {
    method: queryAndHandleMediaPost,
    defaultOptions: {
        collectionId: 'Posts',
        limit: 10,
        sort: {'date.iso': -1},
        filter: {
            featured: true
        },
        filterView: 'filter',
        filterType: 'PostFilter',
        resultType: 'Post',
        fields: ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
    }
}

partsQueryMap[blogAppPartNames.POSTS_LIST] = {
    method: queryAndHandleMediaPost,
    defaultOptions: {
        collectionId: 'Posts',
        limit: 10,
        sort: {'date.iso': -1},
        filterView: 'filter',
        filterType: 'PostFilter',
        resultType: 'Post',
        fields: ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
    }
}

partsQueryMap[blogAppPartNames.FEED] = {
    method: queryFromUrlAndHandleMediaPost,
    defaultOptions: {
        getTotalCount: true,
        collectionId: 'Posts',
        limit: 10,
        sort: '{"date.iso":-1}',
        fields: ['title', 'text', 'coverImageData', 'date', 'permalink', 'permalinkVersion', 'author', 'categoryIds', 'video', 'photo', 'defaultPost', 'description', 'mobileTitle']
    }
}

partsQueryMap[blogAppPartNames.TAG_CLOUD] = {
    method: groupByAndCount,
    defaultOptions: {
        collectionId: 'Posts',
        type: 'Post',
        field: 'tags',
        filter: {},
        sort: 'byKeyAsc',
        normalizeTo: 5
    }
}

partsQueryMap[blogAppPartNames.SINGLE_POST] = {
    method: readItemFromUrl,
    defaultOptions: {
        collectionId: 'Posts',
        acceptableTypes: [
            'PhotoPost',
            'VideoPost',
            'TextPost'
        ],
        filter: {
            draft: false
        },
        sort: {'date.iso': -1}
    }
}

partsQueryMap[blogAppPartNames.RELATED_POSTS] = {
    method: readRelatedPosts,
    defaultOptions: {
        collectionId: 'RelatedPosts'
    }
}

/***************************** Menu *****************************/
partsQueryMap['1660c5f3-b183-4e6c-a873-5d6bbd918224'] = {
    method: readItem,
    defaultOptions: {
        collectionId: 'Menus',
        itemId: 'SampleMenu1'
    }
}

/***************************** FAQ *****************************/
partsQueryMap['f2c4fc13-e24d-4e99-aadf-4cff71092b88'] = {
    method: readItem,
    defaultOptions: {
        collectionId: 'FAQs',
        itemId: 'SampleMenu1'
    }
}

/***************************** NEWS *****************************/
partsQueryMap['045dd836-ef5d-11e1-ace3-c0dd6188709b'] = {
    method: readItem,
    defaultOptions: {
        collectionId: 'Lists',
        itemId: 'SampleFeed1'
    }
}

partsQueryMap['63631b64-a981-40c3-8772-40238db5aff6'] = {
    method: readItem,
    defaultOptions: {
        collectionId: 'Items',
        itemId: '0537E434-5F86-4392-BEF5-7DC62B8412B3'
    }
}

function compare(a, b) {
    if (a > b) {
        return 1
    }
    if (a < b) {
        return -1
    }
    return 0
}

const sortOptions = {
    'byKeyAsc'(a, b) {
        return compare(a.key, b.key)
    },
    'byKeyDesc'(a, b) {
        return compare(b.key, a.key)
    },
    'byCountAsc'(a, b) {
        return compare(a.count, b.count)
    },
    'byCountDesc'(a, b) {
        return compare(b.count, a.count)
    }
}

function getItemId(item) {
    return item._iid
}

const MONTHS_TRANSLATION = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December'
}

const blogAppPartHashToName = _.invert(blogAppPartNames)

const getDateWithCounter = (date, count) => {
    const dateParts = date.split('-')
    return `${MONTHS_TRANSLATION[dateParts[1]]} ${dateParts[0]} (${count})`
}

function reportPerformanceBiEvent({wixappsClassicsLogger, isViewerMode, siteId}, compData, startTime) {
    if (isViewerMode) {
        wixappsClassicsLogger.reportEvent(
            CLASSICS_EVENTS.APP_PART_DATA_REQUEST_FINISHED,
            {
                component_id: compData.id,
                app_part_name: blogAppPartHashToName[compData.appPartName],
                duration: _.now() - startTime,
                is_batched: true,
                occurrence_count: 0, // TODO - re-think if we need to implement counter on siteData
                site_id: siteId
            }
        )
    }
}

function transformArchiveItemsResponse(compId, collectionId, keyGen, options, responseData, currentValue) {
    const _itemToDate = key => {
        const date = key.split('-')
        const d = {_type: 'wix:Date', iso: null}
        const iso = new Date(0)
        iso.setFullYear(date[0])
        iso.setDate(15)
        const monthInt = parseInt(date[1], 10)
        iso.setMonth(isNaN(monthInt) ? date[1] - 1 : monthInt - 1)
        d.iso = iso.toString()

        return d
    }
    const comboOptionsItems = _(responseData.payload)
        .map((value, key) => ({
            _type: 'Option',
            text: getDateWithCounter(key, value),
            value: key,
            dateValue: _itemToDate(key),
            count: value,
            selected: false
        }))
        .sortBy(option => Date.parse(option.dateValue.iso))
        .reverse().value()

    const selectAllValue = null

    function getSelectAllText(selectedValue) {
        return selectedValue === selectAllValue ? 'Select Month' : 'Show All'
    }

    comboOptionsItems.unshift({
        _type: 'Option',
        text: getSelectAllText(null),
        getText: getSelectAllText,
        value: 0,
        dateValue: null,
        count: -1,
        selected: true
    })

    const archiveData = {
        _type: 'ComboOptionsList',
        title: '',
        items: comboOptionsItems,
        selectedValue: selectAllValue
    }

    const itemKey = keyGen()
    currentValue[compId] = [collectionId, itemKey]

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}
    currentValue.items[collectionId][itemKey] = archiveData

    return currentValue
}

function transformItemsResponse(compId, collectionId, responseData, currentValue) {
    currentValue[`${compId}_extraData`] = _.omit(responseData.payload, ['items', 'referencedItems', 'unreferencedItems'])
    currentValue[compId] = _.map(responseData.payload.items, item => [collectionId, getItemId(item)])

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}

    _.forEach(responseData.payload.items, item => {
        currentValue.items[collectionId][getItemId(item)] = item
    })

    _.forEach(responseData.payload.referencedItems, (refItem, refItemKey) => {
        const colId = refItemKey.split('/')[0]
        const iid = refItemKey.split('/')[1]
        currentValue.items[colId] = currentValue.items[colId] || {}
        currentValue.items[colId][iid] = refItem
    })

    return currentValue
}

function transformItemResponse(compId, collectionId, responseData, currentValue) {
    const itemId = getItemId(responseData.payload.item)
    currentValue[`${compId}_extraData`] = _.omit(responseData.payload, ['item', 'referencedItems', 'unreferencedItems'])
    currentValue[compId] = [collectionId, itemId]

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}

    // will fix the data if it's media post
    currentValue.items[collectionId][itemId] = mediaPostConverter.fixMediaPostDataRefs(responseData.payload.item)

    _.forEach(responseData.payload.referencedItems, (refItem, refItemKey) => {
        const colId = refItemKey.split('/')[0]
        const iid = refItemKey.split('/')[1]
        currentValue.items[colId] = currentValue.items[colId] || {}
        currentValue.items[colId][iid] = refItem
    })

    return currentValue
}

function transformItemResponseFromUrl(compId, collectionId, responseData, currentValue, appPartDrcAPI) {
    currentValue = transformItemResponse(compId, collectionId, responseData, currentValue)
    const itemId = getItemId(responseData.payload.item)

    let fixedItem = mediaPostConverter.addAuthorFieldWhenMissing(currentValue.items[collectionId][itemId])
    fixedItem = mediaPostConverter.getPostWithConvertedMobileTitle(fixedItem)
    if (fixedItem.deleted) {
        currentValue.items[collectionId][itemId] = []
    } else {
        fixedItem = mediaPostConverter.translateDefaultPosts(fixedItem, appPartDrcAPI.currentLanguage)

        mediaPostConverter.fixMasterPageIdInLinksInside(fixedItem)

        currentValue.items[collectionId][itemId] = fixedItem
        mediaPostConverter.resolveCategories(currentValue, fixedItem)
    }

    // TODO Reut - BOLT-1334
    // if (siteData && siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH) && coreUtils.stringUtils.startsWith(siteData.currentUrl.hash, '#!')) {
    //     siteData.currentUrl.full = siteData.currentUrl.full.replace(`/${itemId}`, `/${fixedItem.permalink}`)
    // }

    return currentValue
}

function transformSingleItemFromQuery(compId, collectionId, responseData, currentValue, appPartDrcAPI) {
    if (responseData.payload.items && responseData.payload.items.length > 0) {
        responseData.payload.item = responseData.payload.items[0]
        return transformItemResponseFromUrl(compId, collectionId, responseData, currentValue, appPartDrcAPI)
    }

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}
    currentValue.items[collectionId]['-1'] = []
    currentValue[compId] = [collectionId, ['-1']]

    return currentValue
}

function generateGroupByKey(postData) {
    return JSON.stringify(postData).replace(/\./g, '')
}

function transformGroupByResponse(compId, collectionId, keyGen, options, responseData, currentValue) { // eslint-disable-line complexity
    const itemKey = keyGen()
    currentValue[compId] = [collectionId, itemKey]

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}
    currentValue.items[collectionId][itemKey] = _.map(responseData.payload, (count, key) => ({
        key,
        count
    }))

    if (options.sort && sortOptions[options.sort]) {
        currentValue.items[collectionId][itemKey] = currentValue.items[collectionId][itemKey].sort(sortOptions[options.sort])
    }
    if (options.limit) {
        currentValue.items[collectionId][itemKey] = currentValue.items[collectionId][itemKey].slice(0, options.limit)
    }
    if (options.normalizeTo) {
        const normalizeMax = parseInt(options.normalizeTo, 10)
        const maxItem = _.maxBy(currentValue.items[collectionId][itemKey], 'count')
        const max = maxItem ? maxItem.count : -1
        _.forEach(currentValue.items[collectionId][itemKey], o => {
            o.normalized = Math.ceil(o.count * normalizeMax / max) // eslint-disable-line @wix/santa/no-side-effects
        })
    }

    return currentValue
}

function getRequestCustomParams(logicParams) {
    const ret = {}
    _.forOwn(logicParams, (param, key) => {
        if (param && param.value) {
            if (_.isString(param.value) && param.value.charAt(0) === '{') {
                ret[key] = JSON.parse(param.value)
            } else {
                ret[key] = param.value
            }
        }
    })
    return ret
}

function hasPartsQuery(appPartName) {
    return partsQueryMap[appPartName]
}

function createPartDataRequest(appPartDrcAPI, compData, appService, urlData) {
    const params = getRequestCustomParams(compData.appLogicParams)

    if (!hasPartsQuery(compData.appPartName)) {
        return []
    }

    params.collectionId = params.collectionId || _.get(partsQueryMap[compData.appPartName], ['defaultOptions', 'collectionId'])

    return partsQueryMap[compData.appPartName].method(appPartDrcAPI, compData, appService, params, urlData)
}

// function createZoomDataRequest(siteData, compData, appService, urlData) {
//     const itemId = wixapps.wixappsUrlParser.getPageSubItemId(siteData, urlData)
//     if (!itemId) {
//         return []
//     }
//
//     const packageName = appService.packageName
//     const data = getDataByCompId(siteData, packageName, compData.id)
//
//     if (data) {
//         if (data[1] === itemId) {
//             const videoItems = getVideoItems(siteData, packageName, data)
//             return videoThumbDataHandler.handleVideoThumbUrls(videoItems, siteData)
//         }
//         clearDataForCompId(siteData, packageName, compData.id)
//     }
//
//     const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions
//
//     const itemPath = [defaultOptions.collectionId, itemId]
//     const dataItem = getDataByPath(siteData, packageName, itemPath)
//
//     if (dataItem) {
//         setDataForCompId(siteData, packageName, compData.id, itemPath)
//         return []
//     }
//
//     const params = {
//         itemId
//     }
//
//     return partsQueryMap[compData.appPartName].method(siteData, compData, appService, params)
// }

// function onCompRequestFailedInSSR() {
// if (!appPartDrcAPI.isInSSR()) {
//     return
// }
// const requestCache = objectUtils.resolvePath(appPartDrcAPI, ['wixapps', packageName, 'requestCache'])
// delete requestCache[compId]
// setCompFailedRequests(true, appPartDrcAPI, packageName, compId)
// }

function readItem(appPartDrcAPI, compData, appService, params, urlData, transformationFn) {
    const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions
    const dataUrl = `${urlUtils.baseUrl(appPartDrcAPI.getExternalBaseUrl())}/apps/lists/1/ReadItem?consistentRead=false`

    const collectionId = params.collectionId || defaultOptions.collectionId
    const itemId = params.itemId || defaultOptions.itemId

    const postData = {
        autoDereferenceLevel: 3,
        collectionId,
        itemId,
        storeId: appService.datastoreId
    }

    let transformFunc = transformationFn ? transformationFn : transformItemResponse
    transformFunc = transformFunc.bind(undefined, compData.id, collectionId)

    return [
        {
            destination: getSiteDataDestination(appService.packageName),
            name: appService.packageName,
            url: dataUrl,
            error: () => appPartDrcAPI.setCompFailedRequest(appService.packageName, compData.id, true),
            data: postData,
            transformFunc: transformAndSetMetaData.bind(this, transformFunc, appPartDrcAPI, appService.packageName, compData.id)
        }
    ]
}

function readItemFromUrl(appPartDrcAPI, compData, appService, params, urlData = {format: siteConstants.URL_FORMATS.SLASH}) {
    let request
    //this will work as long as we won't have item pages in popups
    const primaryRootInfo = appPartDrcAPI.getExistingRootNavigationInfo(appPartDrcAPI.primaryPageId)
    const pageInfo = urlData ? _.assign(primaryRootInfo, urlData) : primaryRootInfo
    if (pageInfo && pageInfo.pageAdditionalData) {
        if (appPartDrcAPI.isUsingSlashUrlFormat && urlData.format === siteConstants.URL_FORMATS.SLASH) {
            const queryParams = appPartDrcAPI.appPageParams
            request = query(appPartDrcAPI, compData, appService, queryParams, urlData, transformSingleItemFromQuery)
        } else {
            const readItemParams = _.merge({}, params, {itemId: pageInfo.pageAdditionalData})
            request = readItem(appPartDrcAPI, compData, appService, readItemParams, urlData, transformItemResponseFromUrl)
        }
    } else {
        request = query(appPartDrcAPI, compData, appService, {limit: 1, itemId: '1'}, urlData, transformSingleItemFromQuery)
    }

    return request
}

function query(appPartDrcAPI, compData, appService, params, urlData, transformationFn) { // eslint-disable-line complexity
    const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions
    const dataUrl = `${urlUtils.baseUrl(appPartDrcAPI.getExternalBaseUrl())}/apps/lists/1/Query?consistentRead=false`
    const transformItemsFn = transformationFn ? transformationFn : transformItemsResponse
    const collectionId = params.collectionId || defaultOptions.collectionId
    const appSpecificFilter = appSpecificFilterMap[collectionId].query

    const getTotalCount = params.getTotalCount || defaultOptions.getTotalCount
    const filter = _.merge(params.filter || defaultOptions.filter || {}, appSpecificFilter)
    if (_.isUndefined(filter.draft)) {
        filter.draft = false
    }
    const sort = params.sort || defaultOptions.sort
    const skip = parseInt(params.skip || defaultOptions.skip, 10) || 0
    const limit = parseInt(params.limit || defaultOptions.limit, 10) || null

    const postData = {
        autoDereferenceLevel: 3,
        collectionId,
        storeId: appService.datastoreId,
        getTotalCount,
        filter,
        sort,
        skip,
        limit
    }

    if (defaultOptions.fields) {
        postData.fields = defaultOptions.fields
    }

    let queryStartTime
    if (!appPartDrcAPI.rendererModel.previewMode) {
        queryStartTime = _.now()
    }

    const wrappedTransformItemsFn = (...wrapedArgs) => {
        if (!appPartDrcAPI.rendererModel.previewMode) {
            reportPerformanceBiEvent(appPartDrcAPI, compData, queryStartTime)
        }

        return transformItemsFn(compData.id, collectionId, ...wrapedArgs)
    }

    return [
        {
            destination: getSiteDataDestination(appService.packageName),
            name: appService.packageName,
            url: dataUrl,
            data: postData,
            error: () => appPartDrcAPI.setCompFailedRequest(appService.packageName, compData.id, true),
            transformFunc: transformAndSetMetaData.bind(this, wrappedTransformItemsFn, appPartDrcAPI, appService.packageName, compData.id)
        }
    ]
}


function queryFromUrlAndHandleMediaPost(appPartDrcAPI, compData, appService, params, urlData) { // eslint-disable-line complexity
    const DEFAULT_PAGE_SIZE = 10
    const MOBILE_PAGE_SIZE = 3

    const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions

    const format = appPartDrcAPI.isMobileView ? 'Mobile' : ''
    const defaultPageSize = appPartDrcAPI.isMobileView ? MOBILE_PAGE_SIZE : DEFAULT_PAGE_SIZE

    const numberOfPostsPerPage = getNumberOfPostsPerPage(compData, format, defaultPageSize) ||
        _.min([defaultOptions.limit, params.limit])
    params.limit = numberOfPostsPerPage

    const pageParams = appPartDrcAPI.appPageParams
    if (!pageParams) {
        // This component is not in AppPage and can't be rendered.
        return []
    }
    if (pageParams.page && Number(pageParams.page)) {
        params.skip = Number(pageParams.page) * numberOfPostsPerPage
    }
    if (pageParams.filter && !_.isEmpty(pageParams.filter)) {
        params.filter = _.merge(params.filter || {}, pageParams.filter)
    }
    if (pageParams.categoryNames) {
        params.categoryNames = pageParams.categoryNames
    }

    return queryAndHandleMediaPost(appPartDrcAPI, compData, appService, params, urlData)
}

function queryAndHandleMediaPost(appPartDrcAPI, compData, appService, params, urlData) {
    if (!extendParamsWithBlogCategoryFilter(appPartDrcAPI, params)) { // Are blog categories unready?
        return []
    }

    const {videoNotFoundUrl} = appPartDrcAPI

    return query(
        appPartDrcAPI,
        compData,
        appService,
        params,
        urlData,
        (compId, collectionId, responseData, currentValue) => transformMediaItemsResponse(compId, collectionId, responseData, currentValue, appPartDrcAPI.currentLanguage || 'en', videoNotFoundUrl)
    )
}

function queryAndHandleMediaPostWithUrllessPagination(appPartDrcAPI, compData, appService, params, urlData) {
    const numberOfPostsPerPage = getNumberOfPostsPerPage(compData)
    const currentPageNumber = appPartDrcAPI.wixappsClassicsPackage.appPartCommonDataManager.getAppPartCommonDataItem(compData.id, 'currentPageNumber', 1)
    const overridenParams = _.merge({}, params, {
        getTotalCount: true,
        limit: numberOfPostsPerPage,
        skip: (currentPageNumber - 1) * numberOfPostsPerPage
    })
    return queryAndHandleMediaPost(appPartDrcAPI, compData, appService, overridenParams, urlData)
}

function archiveGroupByAndCount(appPartDrcAPI, compData, appService, params, urlData) {
    return groupByAndCount(appPartDrcAPI, compData, appService, params, urlData, transformArchiveItemsResponse)
}

function getTagNamesQuery({getExternalBaseUrl}, storeId, packageName) {
    if (packageName !== 'blog') {
        return []
    }

    const dataUrl = `${urlUtils.baseUrl(getExternalBaseUrl())}/apps/lists/1/GroupByAndCount?consistentRead=false`
    const tagsRequest = {
        url: dataUrl,
        data: {
            collectionId: 'Posts',
            field: 'tags',
            filter: {
                'scheduled.iso': {
                    $not: {
                        $gt: '$now'
                    }
                },
                draft: false,
                deleted: {$ne: true}
            },
            type: 'Post',
            storeId
        },
        destination: ['wixapps', 'blog', 'tagNames'],
        name: packageName,
        transformFunc(response) {
            return Object.keys(response.payload || {})
        }
    }
    return [tagsRequest]
}

function groupByAndCount(appPartDrcAPI, compData, appService, params, urlData, transformationFn) { // eslint-disable-line complexity
    const defaultOptions = partsQueryMap[compData.appPartName].defaultOptions
    const dataUrl = `${urlUtils.baseUrl(appPartDrcAPI.getExternalBaseUrl())}/apps/lists/1/GroupByAndCount?consistentRead=false`

    const collectionId = params.collectionId || defaultOptions.collectionId
    const appSpecificFilter = appSpecificFilterMap[collectionId].groupByAndCount
    const field = params.field || defaultOptions.field
    const filter = _.merge(params.filter || defaultOptions.filter || {}, appSpecificFilter)
    const project = params.project || defaultOptions.project
    const type = params.type || defaultOptions.type

    const postData = {
        collectionId,
        storeId: appService.datastoreId,
        field,
        filter,
        project,
        type
    }

    const options = {
        sort: params.sort || defaultOptions.sort,
        limit: params.limit || defaultOptions.limit,
        normalizeTo: params.normalizeTo || defaultOptions.normalizeTo
    }
    const transformGroupByFn = transformationFn ? transformationFn : transformGroupByResponse

    const queryStartTime = _.now()

    function wrappedTransformGroupByFn(...args) {
        reportPerformanceBiEvent(appPartDrcAPI, compData, queryStartTime)

        return transformGroupByFn(
            compData.id,
            collectionId,
            () => generateGroupByKey(postData),
            options,
            ...args
        )
    }

    return [
        {
            destination: getSiteDataDestination(appService.packageName),
            name: appService.packageName,
            url: dataUrl,
            data: postData,
            error: () => appPartDrcAPI.setCompFailedRequest(appService.packageName, compData.id, true),
            transformFunc: (responseData, currentValue) => wrappedTransformGroupByFn(responseData, currentValue, appPartDrcAPI)
        }
    ]
}

function getItemsRequests(getWixAppRequests, appPartDrcAPI, compData, packageName, appService, urlData) {
    try {
        return getWixAppRequests(appPartDrcAPI, compData, appService, urlData)
    } catch (e) {
        appPartDrcAPI.logger.error(`appPartDataRequirementsChecker Error: ${e}`)
        return []
    }
}

function getAppPartRequests(getWixAppRequests, appPartDrcAPI, compInfo, urlData) {
    const appInnerId = compInfo.data.appInnerID
    const appService = appPartDrcAPI.getClientSpecMapEntry(appInnerId)
    if (!appService || !appService.packageName) {
        return []
    }
    const packageName = appService.packageName

    return [
        ...getItemsRequests(getWixAppRequests, appPartDrcAPI, compInfo.data, packageName, appService, urlData),
        ...getTagNamesQuery(appPartDrcAPI, appService.datastoreId, packageName)
    ]
}

function getRequestsForBlogCategories(appPartDrcAPI, compInfo) {
    const appInnerId = compInfo.data.appInnerID
    const appService = appPartDrcAPI.getClientSpecMapEntry(appInnerId)
    if (!appService || !appService.packageName) {
        return []
    }

    return queryBlogCategories(appPartDrcAPI, compInfo.data, appService)
}
//
// // componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('Zoom:AppPart', getAppPartRequests.bind(this, ecomDataRequirementsChecker.checkZoomDataRequirements, createZoomDataRequest, true))

const getRequestsForAppPart = (appPartDrcAPI, compInfo, urlData) => getAppPartRequests(createPartDataRequest, appPartDrcAPI, compInfo, urlData)


function getVideoThumbRequests(appPartDrcAPI, packageName, compId) {
    const data = getDataByCompId(appPartDrcAPI, packageName, compId)
    const videoItems = getVideoItems(appPartDrcAPI, packageName, data)
    return handleVimeoVideoThumbUrls(videoItems)
}

export {
    getRequestsForAppPart,
    getRequestsForBlogCategories,
    getVideoThumbRequests,
    getDescriptorRequest
}
