import {withActions} from '../withActions'
import * as constants from 'santa-renderer/src/utils/constants'
import _ from 'lodash'
import reactDOM from 'react-dom'
import {siteConstants} from 'santa-core-utils'
import {defaultLayoutProps} from './layoutProps'
const layoutUtilsFactory = require('carmi-host-extensions/src/layout/layoutUtils')

const name = 'LayoutAspect'

let layoutUtils // eslint-disable-line @wix/santa/no-module-state
let compRefs // eslint-disable-line @wix/santa/no-module-state

const defaultModel = {
    pendingReLayoutComps: {},
    isBlockedByTransition: false,
    measureMap: {},
    layoutCounter: 0,
    isLayoutUtilsInitialized: false,
    scheduledLayoutPromise: null,
    fontLoaded: false,
    windowResized: false,
    layoutProps: defaultLayoutProps,
    isGeneratingAnchors: true,
    forceGenerateCounter: 0,
    isEnforcingAnchors: true,
    fullLayoutNeeded: true,
    masterPagesSectionsTight: null
}

function init(aspectActions, {eventsManager, initialData}) {
    compRefs = initialData.compRefs

    if (!initialData.isInSSR) {
        eventsManager.on(constants.EVENTS.FONT_LOAD, () => aspectActions.setFontLoaded(true))
    }
}

const previousAnchors = new Map()
const generateAnchorsImpl = ({requireFn, pageComps, pageId, flags, siteTheme, componentsTemplateId, viewMode, shouldApplyMobileTightLayout}) => {
    const coreUtils = requireFn('coreUtils')
    const deepStructure = coreUtils.flatStructureUtil.buildDeepStructure(pageComps[pageId], pageComps)

    if (flags.forceMobileStructure) {
        deepStructure.mobileComponents = deepStructure.children || deepStructure.components
    }

    if (pageId === 'masterPage' && flags.forceMobileStructure && shouldApplyMobileTightLayout) {
        return {
            [viewMode]: coreUtils.layoutAnchors.createMobileTightMasterPageAnchors(deepStructure, siteTheme, flags)
        }
    }
    return {
        [viewMode]: coreUtils.layoutAnchors.createPageAnchors(deepStructure, siteTheme, flags, componentsTemplateId)
    }
}

const createChildrenAnchors = ({requireFn, pageComps, compId, flags, siteTheme, componentsTemplateId, pageId}) => {
    const coreUtils = requireFn('coreUtils')
    const deepStructure = coreUtils.flatStructureUtil.buildDeepStructure(pageComps[compId], pageComps)
    const isPage = compId === pageId
    if (flags.forceMobileStructure && isPage) {
        deepStructure.mobileComponents = deepStructure.children || deepStructure.components
    }
    return coreUtils.layoutAnchors.createChildrenAnchors(deepStructure, siteTheme, flags, componentsTemplateId)
}

const regenerateAnchorsForComp = (params, compId, currentAnchorsMap) => {
    const {pageComps} = params
    if (!pageComps[compId]) {
        return {}
    }
    const childrenAnchors = createChildrenAnchors({...params, compId})
    const parentId = pageComps[compId].parent
    const siblingAnchors = createChildrenAnchors({
        ...params,
        compId: parentId
    })

    return _(siblingAnchors)
        .assign(childrenAnchors)
        .pickBy(anchors => _.find(anchors, {targetComponent: compId}))
        .mapValues((anchors, pusherId) => _(currentAnchorsMap[pusherId])
            .reject({targetComponent: compId})
            .concat(_.find(anchors, {targetComponent: compId}))
            .value()
        )
        .defaults(currentAnchorsMap)
        .value()
}

const regenerateAnchorsForLockedComps = (currentAnchors, lockedComps, params) => {
    const {viewMode} = params
    const currentPageAnchors = currentAnchors[viewMode]
    const regenerated = _.reduce(lockedComps, (acc, v, compId) => _.assign(acc, regenerateAnchorsForComp(params, compId, acc)), currentPageAnchors)
    return {
        [viewMode]: _.defaults(regenerated, currentAnchors[viewMode])
    }
}

const generateAnchors = params => {
    const {pageId, getIsGeneratingAnchors, viewMode, getLockedComps} = params
    const prevAnchors = previousAnchors.get(pageId)
    const lockedComps = getLockedComps()
    const hasLockedComps = lockedComps && !_.isEmpty(lockedComps)
    const isGenerating = getIsGeneratingAnchors() || hasLockedComps
    if (!isGenerating && prevAnchors && prevAnchors[viewMode]) {
        return prevAnchors
    }

    const nextAnchors = hasLockedComps ? regenerateAnchorsForLockedComps(prevAnchors, lockedComps, params) : generateAnchorsImpl(params)

    const result = _.assign({}, previousAnchors.get(pageId), nextAnchors)
    previousAnchors.set(pageId, result)
    return result
}

function triggerComponentDidLayoutRecursive(comp) {
    if (comp.componentDidLayout) {
        comp.componentDidLayout.call(comp)
    }
    if (comp.componentDidLayoutAnimations) {
        comp.componentDidLayoutAnimations.call(comp)
    }
    _.forOwn(comp.refs, triggerComponentDidLayoutRecursive)
}

const functionLibrary = {
    getLayoutMechanism: (currentUrl, siteMeshReadyFallbackFlag, browserFlags, masterPageLayoutSettings, experiments, layoutMechanismRenderFlag) => {
        if (experiments.bv_forceMesh) {
            return siteConstants.LAYOUT_MECHANISMS.MESH
        }
        const forcedLayoutMechanism = _.get(currentUrl, ['query', 'layoutMechanism'])
        if (forcedLayoutMechanism || layoutMechanismRenderFlag) {
            return forcedLayoutMechanism || layoutMechanismRenderFlag
        }
        if (!experiments.sv_meshLayout || !browserFlags.cssGridSupported) {
            return siteConstants.LAYOUT_MECHANISMS.ANCHORS
        }
        if (_.has(masterPageLayoutSettings, 'mechanism')) {
            return masterPageLayoutSettings.mechanism
        }
        if (siteMeshReadyFallbackFlag) {
            return siteConstants.LAYOUT_MECHANISMS.MESH
        }
        return siteConstants.LAYOUT_MECHANISMS.ANCHORS
    },
    generateAnchors,
    isMasterPagesSectionsTight: withActions((aspectActions, masterPageSectionLayouts, masterPagesSectionsTight) => {
        const setMasterPagesSectionsTightVal = val => {
            if (_.isNil(masterPagesSectionsTight)) {
                aspectActions.setMasterPagesSectionsTight(val)
            }
        }
        for (let i = 0; i < masterPageSectionLayouts.length - 1; i++) {
            const currentSection = masterPageSectionLayouts[i]
            const nextSection = masterPageSectionLayouts[i + 1]
            const currentSectionBottom = currentSection.y + currentSection.height
            const nextSectionTop = nextSection.y
            if (currentSectionBottom !== nextSectionTop) {
                setMasterPagesSectionsTightVal(false)
                return false
            }
        }
        setMasterPagesSectionsTightVal(true)
        return true
    }),
    registerLayoutFuncForNode: (domNode, func) => {
        layoutUtils.registerLayoutFunc(domNode, func)
    },
    registerReLayoutPendingForComp: withActions((aspectActions, compId) => {
        aspectActions.markPendingReLayout(compId, true)
    }),
    runLayout: withActions((aspectActions, checkCanRunLayout, getCurrentLayoutData, getLayoutCounter, reportInteractivite, browser) => {
        if (!checkCanRunLayout) {
            aspectActions.setScheduledLayoutPromise(null)
            return
        }

        const layout = () => {
            aspectActions.$runInBatch(() => {
                let layoutResult = {
                    measureMap: {}
                }
                try {
                    layoutResult = layoutUtils.runLayout({...getCurrentLayoutData(), compRefs})
                } catch (e) {
                    console.error(e)
                }

                const {measureMap, reLayoutedCompsMap} = layoutResult
                const currentLayoutCounter = getLayoutCounter()
                reportInteractivite(currentLayoutCounter)
                aspectActions.setPendingReLayoutComps({})
                aspectActions.setFontLoaded(false)
                aspectActions.setWindowResized(false)
                aspectActions.setLayoutCounter(currentLayoutCounter + 1)
                aspectActions.setMeasureMap(measureMap)
                aspectActions.setScheduledLayoutPromise(null)
                aspectActions.setReLayoutedComps(reLayoutedCompsMap)
                aspectActions.setFullLayoutNeeded(false)
            })
        }

        if (browser.safari) {
            const {currentNavigationInfo} = getCurrentLayoutData()
            const currentPageId = currentNavigationInfo.pageId
            const pageNotDisplayed = _.get(getComputedStyle(document.getElementById(currentPageId)), 'display') === 'none'
            // Fixes bug in Safari - BOLT-1793 - when navigating to a page that was already rendered before,
            // layout runs before the page 'display:none' property is removed (rerendered without the property)
            // In this case our measures are incorrect, leaving images with height:0
            // We recognize this case in order to run layout in requestAnimationFrame, when the page is not in display:none anymore
            // sorry for this
            if (pageNotDisplayed) {
                requestAnimationFrame(layout)
                return
            }
        }

        layout()
    }),
    triggerLayout: withActions(({setScheduledLayoutPromise}, runLayout) => {
        if (runLayout) {
            setScheduledLayoutPromise(Promise.resolve().then(runLayout).catch(err => console.error(err)))
        }
    }),
    initSiteData: withActions((aspectActions, getFullLayoutData, prefersReducedMotion) => {
        const warmupUtils = require('warmupUtils')
        const siteData = layoutUtilsFactory.createSiteData(getFullLayoutData(), {warmupUtils, lodash: _}, false, prefersReducedMotion)

        aspectActions.setSiteData(siteData)
    }),
    initLayoutUtils: withActions((aspectActions, siteData) => {
        const warmupUtils = require('warmupUtils')
        const layout = require('layout')

        layoutUtils = layoutUtilsFactory.init({
            siteData,
            dependencies: {warmupUtils, layout, lodash: _, reactDOM}
        })
        aspectActions.setIsLayoutUtilsInitialized(true)
    }),
    triggerComponentDidLayout: layoutCounter => {
        if (layoutCounter > 0) {
            _.forEach(compRefs, triggerComponentDidLayoutRecursive)
        }
    },
    showPage({layoutCounter, compRef}) {
        if (layoutCounter && compRef) {
            reactDOM.findDOMNode(compRef).style.visibility = '' // eslint-disable-line react/no-find-dom-node
        }
    },
    removeImage: id => layoutUtils.imageLoader.removeImage(id),
    getCustomMeasureMap: (customMeasureMap, customId) => _.get(customMeasureMap, customId)
}

export {
    name,
    defaultModel,
    init,
    functionLibrary
}
