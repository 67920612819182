import _ from 'lodash'
import {getSiteDataDestination, getDataByPath} from '../wixappsDataHandler'

const getYouTubePreviewUrl = videoId => `//img.youtube.com/vi/${videoId}/0.jpg`

function getVimeoPreviewUrl(videoId) {
    return `//vimeo.com/api/v2/video/${videoId}.json`
}

function extractVideoPostsByRefs({wixapps}, packageName, path) {
    const postItem = getDataByPath(wixapps[packageName], path)
    return extractVideoPosts(packageName, postItem, path)
}

function extractVideoPosts(packageName, postItem, itemPath) {
    itemPath = itemPath || []
    const postItemBasePath = getSiteDataDestination(packageName).concat(['items']).concat(itemPath)

    const type = postItem && postItem._type

    switch (type) {
        case 'MediaPost':
            return _.map(_.reject(postItem.mediaText.videoList, 'imageSrc'), videoItem => ({
                item: videoItem,
                path: postItemBasePath.concat(['mediaText', 'videoList'])
            }))
        case 'VideoPost':
            return !postItem.video.imageSrc && [
                {item: postItem.video, path: postItemBasePath.concat(['video'])}
            ]
        default:
            return null
    }
}

function getVideoItems(appPartDrcAPI, packageName, data) {
    if (_.isArray(data)) {
        if (_.isArray(data[0])) {
            return _(data)
                .map(extractVideoPostsByRefs.bind(undefined, appPartDrcAPI, packageName))
                .flattenDeep()
                .compact()
                .value()
        }

        return extractVideoPostsByRefs(appPartDrcAPI, packageName, data)
    }
    if (_.isObject(data)) {
        return extractVideoPosts(packageName, data)
    }
    return null
}

function createVimeoVideoThumbRequest(videoItem) {
    if (videoItem.item.imageSrc) {
        return null
    }
    const url = getVimeoPreviewUrl(videoItem.item.videoId)
    return {
        destination: videoItem.path,
        name: 'video thumb',
        url,
        transformFunc: transformVimeoVideo
    }
}

function transformVimeoVideo(response, item) {
    const [responseData] = response
    const transformedItem = _.isArray(item) ? findItemWithVideoId(item, responseData.id) : item
    transformedItem.imageSrc = responseData.thumbnail_large

    return transformedItem
}

function findItemWithVideoId(itemArr, videoId) {
    return _.find(itemArr, {videoId: `${videoId}`})
}

function prepareSingleVimeoVideoThumbUrl(singleVideoItem) {
    if (singleVideoItem.item.videoType === 'VIMEO') {
        return createVimeoVideoThumbRequest(singleVideoItem)
    }

    return null
}

const handleVimeoVideoThumbUrls = (videoDataItems = []) =>
    _(videoDataItems)
        .map(dataItem => prepareSingleVimeoVideoThumbUrl(dataItem))
        .compact()
        .value()

export {
    getVideoItems,
    handleVimeoVideoThumbUrls,
    getYouTubePreviewUrl
}
