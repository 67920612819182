import {withActions} from '../withActions'

export const name = 'SiteVisibilityAspect'

export const VISIBILITY_STATE = 'state'

export const defaultModel = {
    [VISIBILITY_STATE]: {
        hidden: false
    }
}

export const functionLibrary = {
    updateVisibilityState: withActions(({setDocumentHidden}, windowObject) => {
        if (windowObject && windowObject.document) {
            const hidden = windowObject.document.hidden
            setDocumentHidden(hidden)

            return {hidden}
        }

        return null
    })
}

export function init({}, {eventsManager, initialData: {updateVisibilityState}}) {
    eventsManager.on('__visibilityChange__', () => {
        const state = updateVisibilityState()

        eventsManager.emit('visibilityChange', state)
    })
    updateVisibilityState()
}
