import {aspectNames} from 'common-types'
import _ from 'lodash'

export const stylableModuleName = 'stylable-santa-flatten'
export const stylableRequestFullJsonResponse = 'getAnnotatedStyle'
const STYLABLE_SITE_ASSETS_FETCH_TAG = 'stylable-site-assets'
const STYLABLE_SITE_ASSETS_FETCH_FALLBACK_TAG = 'stylable-site-assets-fallback'

function enhanceFetchTag(tag: string, pageId: string) {
    return `${tag}${pageId === 'masterPage' ? '-masterpage' : ''}`
}

export const name = aspectNames.StylableAspect

/*********************************************************************************************
 Start fallback code
 *********************************************************************************************/

const requirePromise = (requireFn, packageName) => new Promise(resolve => {
    requireFn(packageName, resolve)
})

const fetchFallbackJson = (fetchFn, fallbackUrls) => new Promise((resolve, reject) => {
    let fallbackCount = 0

    const tryToFetch = error => {
        if (fallbackCount >= fallbackUrls.length) {
            reject(error)
            return
        }

        const fallbackUrl = fallbackUrls[fallbackCount++]
        fetchFn(fallbackUrl, null, 'json', resolve, tryToFetch)
    }

    tryToFetch(null)
})

const fetchFromFallback = async ({fallbackUrls, fallbackUrlsMasterPage, onSuccess, onError, requireFn, fetchFn, getDataFixerParams, isMobileView, mediaRootUrl, staticMediaUrl}: {fallbackUrls: string[], fallbackUrlsMasterPage: string[], onSuccess: (v: any) => void, onError: (e: Error) => void, requireFn: (s: string) => void, fetchFn: () => void, getDataFixerParams: () => any[], isMobileView: boolean, mediaRootUrl: string, staticMediaUrl: string}) => {
    // This is coupled with stylable-santa-flatten file fetch url to provide it the correct files
    const stylableModuleFetcher = {
        async fetch({fromUrl}: {fromUrl: string}): Promise<any> {
            if (fromUrl.endsWith('wix-ui-santa.metadata.json.bundle.js')) {
                return requirePromise(requireFn, 'wix-ui-santa/wix-ui-santa.metadata.json.bundle')
            }
            if (fromUrl.endsWith('stylable-preview.bundle.js')) {
                return requirePromise(requireFn, 'wix-ui-santa/stylable-preview.bundle')
            }
            throw new Error(`Failed to load from ${fromUrl} in ${name}`)
        }
    }

    const requireStylableSantaFlattenExecutor = async () => await requirePromise(requireFn, stylableModuleName)
    try {
        const [pageJson, masterPageJson, dataFixer, stylableSantaFlattenExecutor]: any[] = await Promise.all([
            fetchFallbackJson(fetchFn, fallbackUrls),
            fetchFallbackJson(fetchFn, fallbackUrlsMasterPage),
            requirePromise(requireFn, 'santa-data-fixer'),
            requireStylableSantaFlattenExecutor()
        ])
        const pageId = _.get(pageJson, ['structure', 'id'], 'masterPage')
        const fixedPage = dataFixer.fix({
            ...getDataFixerParams(),
            pageId,
            pageJson
        })

        const masterPage = dataFixer.fix({
            ...getDataFixerParams(),
            pageId: 'masterPage',
            pageJson: masterPageJson
        })
        const pages = {masterPage}
        pages[pageId] = fixedPage
        onSuccess(await stylableSantaFlattenExecutor.api({topology: {mediaRootUrl, staticMediaUrl}, moduleFetcher: stylableModuleFetcher}).execute(
            {
                pages,
                getAnnotatedStyle: 'true',
                isMobileView: isMobileView ? 'true' : 'false',
                libVer: '1.0.0' // Dummy
            }
        ))
    } catch (err) {
        if (onError) {
            onError(err)
            return
        }
        throw err
    }
}
/*********************************************************************************************
 End fallback code
 *********************************************************************************************/

const fetchStylable = (fetchFn, url: string, setPagesCssFn, pageId: string, interactionStartedFn, interactionEndedFn, captureErrorFn, fallbackUrls: string[], fallbackUrlsMasterPage: string[], requireFn, isMobileView: boolean, getDataFixerParams: any, mediaRootUrl: string, staticMediaUrl: string, pageIdToStylableStyleIdToCssSetter, isStylableInteraction): void => {
    const fetchTag = enhanceFetchTag(STYLABLE_SITE_ASSETS_FETCH_TAG, pageId)
    const fetchFallbackTag = enhanceFetchTag(STYLABLE_SITE_ASSETS_FETCH_FALLBACK_TAG, pageId)

    interactionStartedFn(fetchTag)
    const onFetchSuccess = (tag: string) => (...args) => {
        interactionEndedFn(tag)
        if (isStylableInteraction) {
            pageIdToStylableStyleIdToCssSetter(pageId, args[0].css.styles)
            setPagesCssFn(pageId, args[0].css.global)
        } else {
            setPagesCssFn(pageId, args[0].css)
        }
    }

    /*********************************************************************************************
     Start fallback code
     *********************************************************************************************/
    const onFallbackError = () => {
        captureErrorFn(new Error('stylableSiteAssetsFetchError'), {tags: {interactionName: fetchFallbackTag}})
    }
    /*********************************************************************************************
     End fallback code
     *********************************************************************************************/

    const onFetchFail = () => {
        captureErrorFn(new Error('stylableSiteAssetsFetchError'), {tags: {interactionName: fetchTag}})
        interactionStartedFn(fetchFallbackTag)
        fetchFromFallback({fallbackUrls, fallbackUrlsMasterPage, requireFn, onSuccess: onFetchSuccess(fetchFallbackTag), onError: onFallbackError, fetchFn, isMobileView, getDataFixerParams, mediaRootUrl, staticMediaUrl})
    }

    return fetchFn(url, null, 'json', onFetchSuccess(fetchTag), onFetchFail)
}

export const functionLibrary = {
    fetchStylable
}
