import _ from 'lodash'
import {getSiteDataDestination} from './wixappsDataHandler'

const getDescriptorUrl = ({santaBase}, packageName) => {
    const delimiter = _.last(santaBase) !== '/' ? '/' : ''
    return `${santaBase}${delimiter}static/wixapps/apps/${packageName}/descriptor.json`
}

const getDescriptorRequest = (getAppPartDrcAPI, packageName) => {
    const destination = getSiteDataDestination(packageName).concat(['descriptor'])
    const descriptorUrl = getDescriptorUrl(getAppPartDrcAPI(), packageName)
    return {
        url: descriptorUrl,
        destination,
        name: packageName,
        transformFunc(descriptorObj) {
            if (_.has(descriptorObj, 'generatedViews')) {
                descriptorObj.views = descriptorObj.views.concat(descriptorObj.generatedViews)
                delete descriptorObj.generatedViews
            }

            return descriptorObj
        }
    }
}

export {
    getDescriptorUrl,
    getDescriptorRequest
}
