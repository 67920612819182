const _ = require('lodash')
const {invokeTrackEvent, initializeTags} = require('./promoteAnalytics')
const thirdPartyAnalytics = require('thirdPartyAnalytics')
const {withActions} = require('carmi-host-extensions')
const {registerTagManagerListeners} = require('./register-tag-manager-listeners')

const name = 'AnalyticsAspect'

const defaultModel = {
    promoteAnalyticsAdapter: null,
    isAdapterInitialized: false,
    setIsGoogleAnalyticsInitialized: false,
    loadedScripts: {},
    shouldInitTags: false,
    isAnalyticsReady: false
}

const trackEvent = (analyticsProps, packageProps = {}, eventName, params, options, analyticsActions) => {
    if (analyticsProps.isPreviewMode) {
        return Promise.resolve()
    }
    const {promoteAnalyticsAdapter} = packageProps
    if (!promoteAnalyticsAdapter) {
        return new Promise(resolve => {
            packageProps.requireFn('promote-analytics-adapter', analyticsPackage => {
                packageProps.setAnalyticsAdapterPackage(analyticsPackage)
                resolve(invokeTrackEvent(analyticsProps, eventName, params, options, analyticsPackage, analyticsActions))
            })
        })
    }
    return Promise.resolve(invokeTrackEvent(analyticsProps, eventName, params, options, promoteAnalyticsAdapter, analyticsActions))
}

const getPagePath = (currentUrl, externalBaseUrl) => {
    const currentUrlString = _.isString(currentUrl) ? currentUrl : currentUrl.full || ''
    const baseUrl = externalBaseUrl.replace(/\/$/, '')
    return currentUrlString.replace(baseUrl, '')
}

const getPageData = analyticsProps => ({
        pagePath: getPagePath(analyticsProps.currentUrl, analyticsProps.externalBaseUrl),
        pageTitle: analyticsProps.pageTitle,
        pageId: analyticsProps.getPageId(),
        pageNumber: analyticsProps.getPageNumber()
    })

const reportPageEvent = withActions((analyticsActions, analyticsProps = {}, packageProps) => {
    const {currentUrl = {}, lastPageViewed} = analyticsProps
    if (currentUrl.full === lastPageViewed) {
        return
    }
    const pageData = getPageData(analyticsProps)
    const isFirstVisit = !lastPageViewed
    trackEvent({...analyticsProps, reportToChannelsOnly: true}, packageProps, 'PageView', pageData, {context: {isFirstVisit}}, analyticsActions)
    if (analyticsProps.isAnalyticsReady) {
        analyticsActions.setLastPageViewUrl(analyticsProps.currentUrl.full)
    }
})

const reportPageEventToListeners = withActions((analyticsActions, analyticsProps = {}, packageProps) => {
    const {currentUrl = {}, lastPageViewUrlForListeners} = analyticsProps
    if (currentUrl.full === lastPageViewUrlForListeners) {
        return
    }
    const pageData = getPageData(analyticsProps)
    const isFirstVisit = !lastPageViewUrlForListeners
    trackEvent({...analyticsProps, reportToListenersOnly: true}, packageProps, 'PageView', pageData, {context: {isFirstVisit}}, analyticsActions)
    analyticsActions.setLastPageViewUrlForListeners(analyticsProps.currentUrl.full)
})

const functionLibrary = {
    fireFacebookRemarketingPixel: (analyticsProps, eventName, eventData) => thirdPartyAnalytics.fireFacebookRemarketingPixel(analyticsProps, eventName, eventData),
    fireFacebookCustomEvent: (analyticsProps, eventType, eventName, data) => thirdPartyAnalytics.fireFacebookCustomEvent(analyticsProps, eventType, eventName, data),
    fireGoogleRemarketing: () => thirdPartyAnalytics.fireGoogleRemarketingPixel(),
    reportGoogleAnalytics: (analyticsProps, eventParams) => thirdPartyAnalytics.reportGoogleAnalytics(analyticsProps, eventParams),
    reportGoogleTagManager: (analyticsProps, eventParams) => thirdPartyAnalytics.reportGoogleTagManager(analyticsProps, eventParams),
    reportBiAnalytics: (analyticsProps, eventParams) => thirdPartyAnalytics.reportBiAnalytics(analyticsProps, eventParams),
    reportYandexPageHit: url => thirdPartyAnalytics.reportYandexPageHit(url),
    reportPageEvent,
    reportPageEventToListeners,
    initializeTags,
    trackEvent: withActions((analyticsActions, analyticsProps, packageProps, eventName, params, options) =>
        trackEvent({...analyticsProps, reportPageEvent}, packageProps, eventName, params, options, analyticsActions))
}

function init({setLoadedScripts, setShouldInitTags}, {eventsManager, initialData: {packageProps, analyticsProps, isInSSR}}) {
    if (isInSSR) {
        return
    }

    if (!packageProps.promoteAnalyticsAdapter) {
        packageProps.requireFn('promote-analytics-adapter', analyticsPackage => {
            packageProps.setAnalyticsAdapterPackage(analyticsPackage)
        })
        registerTagManagerListeners(analyticsProps, eventsManager, setLoadedScripts, setShouldInitTags)
        return
    }
}

module.exports = {
    name,
    defaultModel,
    functionLibrary,
    init
}
