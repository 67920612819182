const _ = require('lodash')
const TAG_MANAGER = require('./tag-manager-types')

const {LOAD_STATUS, EVENT_NAMES, SUPPORTED_CHANNELS} = TAG_MANAGER

const supportedChannels = Object.values(SUPPORTED_CHANNELS)

export function registerTagManagerListeners({loadedScripts = {}}, eventsManager, setLoadedScripts, setShouldInitTags) {
    eventsManager.on(EVENT_NAMES.TAGS_LOADING, event => handleTagsLoadingEvent({event}))
    eventsManager.on(EVENT_NAMES.TAG_LOADED, event => handleTagLoadedEvent({event, loadStatus: LOAD_STATUS.SUCCESS}))
    eventsManager.on(EVENT_NAMES.TAG_LOAD_ERROR, event => handleTagLoadedEvent({event, loadStatus: LOAD_STATUS.ERROR}))

    function handleTagsLoadingEvent({event} = {}) {
        const scripts = _.get(event, 'detail')
        const analyticScripts = scripts
		.filter(({config} = {}) => supportedChannels.includes(config && config.type))
		.reduce((scriptsObject, curr) => {
			const script = {
				[curr.id]: {name: curr.name}
			}
			return {...scriptsObject, ...script}
        }, {})
        setScripts(analyticScripts)
    }

    function handleTagLoadedEvent({event, loadStatus} = {}) {
        const embed = _.get(event, ['detail', 'embed'])
        if (embed && embed.config) {
            const script = {[embed.id]: {name: embed.config.type, loadStatus}}
            const scripts = {...loadedScripts, ...script}
            setScripts(scripts)
        }
    }

    function setScripts(scripts = {}) {
        const scriptValues = Object.values(scripts)
        const isLoaded = scriptValues.every(script => script.loadStatus)
        loadedScripts = scripts
        setLoadedScripts(scripts)
        if (isLoaded) {
            setShouldInitTags(true)
        }
    }
}
