import {BaseDataItem, LayoutInBreakpoint} from './structureTypes'

// interface Unit2 {
//     type: 'unit'
//     value: number
//     unit: 'px' | 'pct' |
// }
export interface UnitSize {
    type: 'px' | 'percentage' | 'fr' | 'vh' | 'vw' | 'rem'
    value: number
}

interface KeywordSize {
    type: 'auto' | 'maxContent' | 'minContent'
}

interface AspectRatioSize {
    type: 'aspectRatio',
    value: number
}

// interface Var {
//     type: 'var'
//     variableName: string
//     fallbackValue?: string
// }

interface Calculation {
    type: 'Calc'
    value: string
}

// type Operator = '+' | '-' | '*' | '/'
// interface Calculation {
//     type: 'calculation'
//     operator: Operator
//     operand1: Size
//     operand2: Size
// }
type LayoutSize = KeywordSize | UnitSize | Calculation
export type Height = LayoutSize | AspectRatioSize
interface MinMaxSize {
    type: 'MinMaxSize'
    min: LayoutSize
    max: LayoutSize
}
type GridTrackSize = MinMaxSize | LayoutSize
export type Size = GridTrackSize | Height

export type Direction = 'ltr' | 'rtl'

export type FlexDirection = 'row' | 'rowReverse' | 'column' | 'columnReverse'

export type ScrollSnapAlign = 'center' | 'end' | 'start' | 'none'

export type ScrollSnapDirection = 'x' | 'y'

export type ScrollSnapType = 'mandatory' | 'proximity' | 'none'

export type ScrollBehaviour = 'auto' | 'smooth'

export type Overflow = 'auto' | 'scroll' | 'hidden' | 'visible'

export interface ScrollSnap {
    scrollSnapDirection?: ScrollSnapDirection
    scrollSnapType?: ScrollSnapType
}

// const isUnitSize = (u: Size) : u is UnitSize => u.type === 'unit';
export const isUnitSize = (u: Size): u is UnitSize => u.type === 'px' || u.type === 'percentage' || u.type === 'vw' || u.type === 'vh' || u.type === 'fr' || u.type === 'rem'
export const isAutoSize = (u: Size): u is KeywordSize => u.type === 'auto'
export const isKeywordSize = (u: Size): u is KeywordSize => u.type === 'auto' || u.type === 'maxContent' || u.type === 'minContent'
export const isMinMaxSize = (u: Size): u is MinMaxSize => u.type === 'MinMaxSize'
export const isAspectRatio = (u: Size): u is AspectRatioSize => u.type === 'aspectRatio'

// const isCssVariableSize = (u: Size): u is Var => u.type === 'var'
export const isCalculationSize = (u: Size): u is Calculation => u.type === 'Calc'

export interface LayoutRefs extends BaseDataItem {
    type: 'RefArray' // TODO change type name?
    values: string[]
}

interface Margins {
    left?: LayoutSize
    right?: LayoutSize
    top?: LayoutSize
    bottom?: LayoutSize
}

interface Padding {
    left?: LayoutSize
    right?: LayoutSize
    top?: LayoutSize
    bottom?: LayoutSize
}

export interface BaseItemLayout extends BaseDataItem {
    margins?: Margins
    position?: 'sticky' | 'relative' | 'absolute'
    pinnedToContainer?: boolean
    top?: LayoutSize
    bottom?: LayoutSize
    left?: LayoutSize
    right?: LayoutSize
    scrollSnapAlign?: ScrollSnapAlign
}

export interface ComponentLayout extends BaseDataItem {
    type: 'ComponentLayout'
    width?: LayoutSize
    height?: Height
    minWidth?: LayoutSize
    minHeight?: LayoutSize
    maxWidth?: LayoutSize
    maxHeight?: LayoutSize
    rotationInDegrees?: number
    hidden?: boolean
    direction?: Direction
}

type Alignment = 'auto' | 'stretch' | 'start' | 'center' | 'end'

export interface GridBaseItem extends BaseItemLayout {
  justifySelf?: Alignment
  alignSelf?: Alignment
}

export interface GridItemLayout extends GridBaseItem {
    type: 'GridItemLayout'
    gridArea: {
        rowStart: number,
        columnStart: number,
        rowEnd: number,
        columnEnd: number
    }
}

export interface FixedItemLayout extends GridBaseItem {
    type: 'FixedItemLayout'
}

export interface ContainerBase extends BaseDataItem {
    padding?: Padding
    overflowY?: Overflow
    overflowX?: Overflow
    hideScrollbar?: boolean
    scrollSnap?: ScrollSnap
    scrollBehaviour?: ScrollBehaviour
}

export interface GridContainerLayout extends ContainerBase {
    type: 'GridContainerLayout'
    rows: GridTrackSize[]
    columns: GridTrackSize[]
    rowGap?: LayoutSize
    columnGap?: LayoutSize
}

export interface FlexContainerBase extends ContainerBase {
    direction?: FlexDirection
    wrap?: 'nowrap' | 'wrap' | 'wrapReverse' | 'unset' | 'initial' | 'inherit'
    justifyContent?: 'start' | 'center' | 'end' | 'spaceBetween' | 'spaceAround'
    alignItems?: Alignment
}
export interface FlexContainerLayout extends FlexContainerBase {
    type: 'FlexContainerLayout'
}

export interface StackContainerLayout extends FlexContainerBase {
    type: 'StackContainerLayout'
}

export interface FlexItemBase extends BaseItemLayout {
    alignSelf?: Alignment
    order?: number
    basis?: LayoutSize
    grow?: number
    shrink?: number
}
export interface FlexItemLayout extends FlexItemBase {
    type: 'FlexItemLayout'
}

export interface StackItemLayout extends FlexItemBase {
    type: 'StackItemLayout'
}
// two approaches. editor focused and viewer focused.
// viewer focused - just flex properties. two types referencing the same props.
// editor focused - assuming css from the types. like for example when organizer we will assume
export interface OrganizerContainerLayout extends FlexContainerBase {
    type: 'OrganizerContainerLayout'
}

export interface OrganizerItemLayout extends FlexItemBase {
    type: 'OrganizerItemLayout'
}

export interface SingleLayoutData extends BaseDataItem {
    type: 'SingleLayoutData'
    containerLayout: GridContainerLayout | FlexContainerLayout | OrganizerContainerLayout | StackContainerLayout
    itemLayout: GridItemLayout | FixedItemLayout | StackItemLayout | FlexItemLayout | OrganizerItemLayout
    componentLayout: ComponentLayout
}

export type LayoutDataItems = ComponentLayout
    | GridItemLayout | GridContainerLayout | FixedItemLayout
    | FlexContainerLayout | FlexItemLayout
    | OrganizerContainerLayout | OrganizerItemLayout
    | StackItemLayout | StackContainerLayout | SingleLayoutData

type SingleLayoutItem = LayoutRefs | LayoutDataItems | LayoutInBreakpoint
export interface LayoutData {
    [layoutQuery: string]: SingleLayoutItem
}

export const itemLayoutTypes = ['GridItemLayout', 'FixedItemLayout', 'FlexItemLayout', 'StackItemLayout', 'OrganizerItemLayout']
