import {biLoggerSanitizer} from 'warmupUtils' //eslint-disable-line import/no-unresolved
import {PII_PARAMS} from './constants'

export function resolveEventParams(params = {}) {
	const paramKeys = Object.keys(params)

	return paramKeys.reduce((result, paramKey) => {
		const hashedParam = {
			[paramKey]: PII_PARAMS[paramKey] ? biLoggerSanitizer.hashString(params[paramKey]) : params[paramKey]
		}
		return {...result, ...hashedParam}
	}, {})
}
