import React from 'react'
import PropTypes from 'prop-types'
import {santaTypesDefinitions, utils} from 'santa-components'
import forwardRefHOC from 'santa-renderer/src/utils/forwardRefHOC'

export const pageSantaTypesDefinitions = utils.createSantaTypesDefinitions({
    key: PropTypes.string,
    hidePage: PropTypes.func
}, 'BoltPageHOC')

const pageRefCallback = (page, props) => {
    props.forwardedRef(page)
    if (page) {
        props.hidePage(props.id)
    }
}

const withBoltPage = Page => {
    const BoltPage = props => <Page {...props} ref={page => pageRefCallback(page, props)}/>

    BoltPage.propTypes = {
        key: pageSantaTypesDefinitions.key,
        id: santaTypesDefinitions.Component.id,
        hidePage: pageSantaTypesDefinitions.hidePage,
        forwardedRef: PropTypes.any
    }
    BoltPage.displayName = `withBoltPage(${Page.displayName})`

    return BoltPage
}

export const boltPageHOC = forwardRefHOC(withBoltPage)
