export const SERVER_EVENTS_APP_DEF_ID = 'cf06bdf3-5bab-4f20-b165-97fb723dac6a'
export const FB_SERVER_EVENTS_SPECS = {
	STORES_PURCHASE: 'specs.promote.ar.useStoresPurchaseFBServerEvent',
	EVENTS_PURCHASE: 'specs.promote.ar.useEventsPurchaseFBServerEvent',
	BOOKINGS_PURCHASE: 'specs.promote.ar.useBookingsPurchaseFBServerEvent'
}
export const PII_PARAMS = {
	buyerMail: 'buyerMail',
	buyerId: 'buyerId'
}
