import _ from 'lodash'

const overrideTypeToRuntimeDalMethod = {
    data: 'setCompData',
    props: 'setCompProps',
    layout: 'updateCompLayout',
    design: 'setCompDesign',
    style: 'updateCompStyle'
}

export const handleBatchCommand = (runtimeDal, batchData) => {
    _.forOwn(batchData, (overridesByType, compId) => {
        _.forOwn(overridesByType, (overrides, overrideType) => {
            if (overrideType === 'registerEvent') {
                _.forEach(overrides, componentEvent => {
                    runtimeDal.registerComponentEvent(compId, componentEvent)
                })
                return
            }

            runtimeDal[overrideTypeToRuntimeDalMethod[overrideType]](compId, overrides, true)
        })
    })
}
