const CLASSICS_CLASSIFICATIONS = {
    CLASSICS_EVENT_SOURCE: 12,
    Type: {ERROR: 10, TIMING: 20, FUNNEL: 30, USER_ACTION: 40},
    Category: {EDITOR: 1, VIEWER: 2, CORE: 3, SERVER: 4},
    Issue: {SERVER_EDITOR_ERROR: 0, SERVER_VIEWER_ERROR: 1, CLIENT_EDITOR_ERROR: 2, CLIENT_VIEWER_ERROR: 4},
    Severity: {RECOVERABLE: 10, WARNING: 20, ERROR: 30, FATAL: 40}
}

const SAMPLE_RATIOS = {
    ALWAYS: 1,
    ONCE_PER_APP_PART: 10,
    MANY_PER_APP_PART: 30
}

const CLASSICS_EVENTS = {
    /**
     * Add new EVENTS here!
     */
    APP_PART_LOADING_STAGES: {
        adapter: 'blog-ugc',
        description: 'measure time for a stage',
        eventId: 511,
        params: {
            component_id: 'component_id',
            component_type: 'component_type',
            startTime: 'startTime',
            endTime: 'endTime',
            stage_process: 'stage_process',
            site_id: 'site_id'
        },
        src: 12
    },
    SITE_PUBLISHED_WITH_BLOG: {eventId: 64, description: 'site published with blog'},
    CATEGORY_CLICKED: {eventId: 502, description: 'category clicked', params: {
        site_id: 'site_id'
    }
    },
    TAG_CLICKED: {eventId: 503, description: 'tag clicked', params: {
        site_id: 'site_id'
    }
    },
    SHARE_CLICKED: {
        eventId: 504,
        description: 'share clicked',
        params: {
            type: 'type',
            post_id: 'post_id',
            site_id: 'site_id'
        },
        sampleRatio: SAMPLE_RATIOS.ALWAYS
    },
    SINGLE_POST_LINK_CLICKED: {eventId: 505, description: 'single post link clicked', params: {
        post_id: 'post_id',
        site_id: 'site_id',
        msid: 'msid'
    }
    },
    SELECTION_SHARER_OPENED: {eventId: 507, description: 'share part of text popup', params: {
        post_id: 'post_id',
        site_id: 'site_id'
    }
    },
    SELECTION_SHARER_CLICKED: {eventId: 508, description: 'share part of text click', params: {
        type: 'type',
        post_id: 'post_id',
        site_id: 'site_id'
    }
    },
    LIKE_CLICKED: {eventId: 512, description: 'User click on like', params: {
        like_status: 'like_status',
        post_id: 'post_id'
    }
    },
    APP_PART_RENDERED: {
        adapter: 'blog-ugc',
        description: 'appPart has rendered',
        eventId: 513,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            duration: 'duration', // milliseconds
            app_part_state: 'app_part_state', // 'loading', 'error', 'content'
            occurrence_count: 'occurrence_count',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.MANY_PER_APP_PART
    },
    APP_PART_DATA_REQUEST_FINISHED: {
        adapter: 'blog-ugc',
        description: 'appPart data request returned from server',
        eventId: 514,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            duration: 'duration', // milliseconds
            is_batched: 'is_batched',
            occurrence_count: 'occurrence_count',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.ONCE_PER_APP_PART
    },
    FIRST_TIME_APP_PART_RENDER_START: {
        adapter: 'blog-ugc',
        description: 'appPart started first time render',
        eventId: 516,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.ONCE_PER_APP_PART
    },
    FIRST_TIME_APP_PART_RENDER_FINISH: {
        adapter: 'blog-ugc',
        description: 'appPart finished rendering with content/error for the first time',
        eventId: 517,
        params: {
            component_id: 'component_id',
            app_part_name: 'app_part_name',
            app_part_state: 'app_part_state', // error / content
            duration: 'duration', // milliseconds
            render_duration: 'render_duration',
            site_id: 'site_id'
        },
        src: 12,
        sampleRatio: SAMPLE_RATIOS.ONCE_PER_APP_PART
    }
}

const CLASSICAS_ERRORS = {
    /**
     * Add new ERRORS here!
     */
    GENERIC_ERROR: {code: -20000, description: 'classics unspecified error', packageName: '{%= name %}'},
    APP_PART_FAILED_TO_LOAD: {code: -20011, description: 'Failed to load app part', issue: CLASSICS_CLASSIFICATIONS.Issue.CLIENT_VIEWER_ERROR, packageName: '{%= name %}'}
}

const CLASSICS_EVENT_DEFAULT_VALUES = {
    type: CLASSICS_CLASSIFICATIONS.Type.USER_ACTION,
    adapter: 'blog-ugc',
    category: CLASSICS_CLASSIFICATIONS.Category.VIEWER,
    reportType: 'event',
    packageName: 'blog',
    params: {},
    src: CLASSICS_CLASSIFICATIONS.CLASSICS_EVENT_SOURCE
}

const CLASSICS_ERROR_DEFAULT_VALUES = {
    desc: CLASSICAS_ERRORS.GENERIC_ERROR.description,
    errorCode: CLASSICAS_ERRORS.GENERIC_ERROR.code,
    type: CLASSICS_CLASSIFICATIONS.Type.ERROR,
    issue: CLASSICS_CLASSIFICATIONS.Issue.CLIENT_VIEWER_ERROR,
    severity: CLASSICS_CLASSIFICATIONS.Severity.ERROR,
    category: CLASSICS_CLASSIFICATIONS.Category.VIEWER,
    reportType: 'error',
    packageName: 'blog',
    src: CLASSICS_CLASSIFICATIONS.CLASSICS_EVENT_SOURCE
}

const LISTS_CLASSIFICATIONS = {
    LISTS_EVENT_SOURCE: 60,
    Type: {ERROR: 10, USER_ACTION: 40},
    Category: {EDITOR: 1, VIEWER: 2, CORE: 3, SERVER: 4},
    Issue: {GENERAL: 0, PROXY: 1, APP_LOGIC: 2, DATA_SERVICE: 3, DATA_ITEMS: 4, CORE_WIRING: 5, DATA_EDITING: 6, APP_BUILDER: 7, APP_REPO_SERVICE: 8},
    Severity: {RECOVERABLE: 10, WARNING: 20, ERROR: 30, FATAL: 40}
}


const LIST_ERRORS = {
    /**
     * Add new ERRORS here!
     */
    GENERIC_ERROR: {errorCode: -20000, desc: 'WixApps unspecified error', packageName: 'wixapps'},
    APP_PART2_FAILED_TO_LOAD: {errorCode: -20011, desc: 'Failed to load app part', issue: LISTS_CLASSIFICATIONS.Issue.APP_BUILDER, packageName: 'wixapps'},
    DATA_SELECTOR_CONTAINS_NULL: {errorCode: -20013, desc: 'A data selector returned from the server with a null id key.', issue: LISTS_CLASSIFICATIONS.Issue.APP_BUILDER, packageName: 'wixapps'},
    MISSING_PERMALINK: {errorCode: -22041, desc: 'Site data is missing the permalink data item', packageName: 'wixapps'},
    REQUEST_FAILED: {errorCode: -1, desc: 'Unspecified error occurred, possibly a connection problem', packageName: 'wixapps'}
}

const LISTS_EVENTS = {
    /**
     * Add new EVENTS here!
     */
    APP_BUILDER_PART_LOADED: {eventId: 103, desc: 'App builder - part loaded in published', params: {c2: 'appPartName', g2: 'userId'}},
    APP_PART2_FAILED_TO_LOAD_DATA_SELECTOR: {eventId: 135, desc: 'Failed to load data selector', params: {c1: 'dataSelector'}},
    APP_PART2_FAILED_TO_LOAD_PART_DEFINITION: {eventId: 136, desc: 'Failed to load app part definition'}, // TODO: event isn't being used anywhere
    VIEW_DEFINITION_NOT_FOUND: {eventId: 137, desc: 'Failed to load view definition'}, // TODO: event isn't being used anywhere
    TAG_SELECTED_IN_VIEWER: {eventId: 305, desc: 'User selected a tag in the viewer.'}
}

const LISTS_ERROR_DEFAULT_VALUES = {
    desc: LIST_ERRORS.GENERIC_ERROR.desc,
    errorCode: LIST_ERRORS.GENERIC_ERROR.errorCode,
    type: LISTS_CLASSIFICATIONS.Type.ERROR,
    issue: LISTS_CLASSIFICATIONS.Issue.GENERAL,
    severity: LISTS_CLASSIFICATIONS.Severity.ERROR,
    category: LISTS_CLASSIFICATIONS.Category.VIEWER,
    src: LISTS_CLASSIFICATIONS.LISTS_EVENT_SOURCE
}

const LISTS_EVENT_DEFAULT_VALUES = {
    type: LISTS_CLASSIFICATIONS.Type.USER_ACTION,
    adapter: 'lists',
    category: LISTS_CLASSIFICATIONS.Category.VIEWER,
    src: LISTS_CLASSIFICATIONS.LISTS_EVENT_SOURCE
}

export {
    LIST_ERRORS,
    LISTS_EVENTS,
    LISTS_ERROR_DEFAULT_VALUES,
    LISTS_EVENT_DEFAULT_VALUES,
    CLASSICS_CLASSIFICATIONS,
    CLASSICS_EVENTS,
    CLASSICS_EVENT_DEFAULT_VALUES,
    CLASSICS_ERROR_DEFAULT_VALUES
}
