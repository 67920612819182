const _ = require('lodash')

const appPagesParsers = {
    '79f391eb-7dfc-4adf-be6e-64434c4838d9': {
        parse(urlExtraData) { // eslint-disable-line complexity
            const splitUri = urlExtraData.split('/')
            const params = {}
            params.filter = {}
            if (splitUri[0] === 'Featured') {
                params.filter.featured = true
                splitUri.shift()
            }
            for (let i = 0; i < splitUri.length; i += 2) {
                switch (splitUri[i]) {
                    case 'Type':
                        params.filter._type = splitUri[i + 1]
                        break
                    case 'Tag':
                    case 'tag':
                        const tagsFilter = decodeURIComponent(splitUri[i + 1])
                        if (_.includes(tagsFilter, '-')) {
                            params.filter.tags = {$in: [tagsFilter, tagsFilter.replace(/-/g, ' ')]}
                        } else {
                            params.filter.tags = tagsFilter
                        }
                        break
                    case 'Category':
                    case 'category':
                        const categoryNameWithDashes = decodeURIComponent(splitUri[i + 1])
                        const categoryNameWithSpaces = categoryNameWithDashes.replace(/-/g, ' ')
                        params.categoryNames = JSON.stringify([
                            categoryNameWithDashes,
                            categoryNameWithSpaces
                        ])
                        break
                    case 'Page':
                    case 'page':
                        params.page = splitUri[i + 1]
                        break
                    case 'Author':
                    case 'author':
                        const authorFilter = decodeURIComponent(splitUri[i + 1])
                        if (_.includes(authorFilter, '-')) {
                            params.filter.author = {$in: [authorFilter, authorFilter.replace(/-/g, ' ')]}
                        } else {
                            params.filter.author = authorFilter
                        }
                        break
                    case 'Sort':
                        params.sort = splitUri[i + 1]
                        break
                    case 'Date':
                    case 'date':
                        const date = splitUri[i + 1]
                        params.filter['date.iso'] = {$regex: `^${date}`}
                }
            }
            return params
        }
    },
    '7326bfbb-4b10-4a8e-84c1-73f776051e10': {
        parse(urlExtraData) {
            const params = {}
            params.limit = 1

            params.filter = {}
            params.filter.permalink = {$in: [urlExtraData, decodeURIComponent(urlExtraData)]}

            return params
        }
    }
}

const getAppPageParams = (appPage, urlExtraData) =>
    appPagesParsers[appPage] ? appPagesParsers[appPage].parse(urlExtraData) : null

export {
    getAppPageParams
}
