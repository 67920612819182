import _ from 'lodash'
const {withActions} = require('carmi-host-extensions')
const {Effect} = require('./components/Effect')
import React from 'react'

export const name = 'TPAWidgetNativeAspect'
export const functionLibrary = {
    setReactComponent: withActions((actions, widgetId, obj) => {
        const reactComp = _.get(obj, 'default.component')
        const getCompCss = _.get(obj, 'default.getCompCss')
        actions.setNativeComponentData(widgetId, {component: reactComp, getCompCss})
    }),
    resetTPANativeProps: withActions(actions => actions.setTPAWidgetNativeCompPropsInitData({})),
    resolveControllersPropsEvents: withActions(({setControllerProps, setPendingControllersPropsUpdates, $runInBatch}, getCurrentProps, propsUpdateEvents) => {
        const currentProps = getCurrentProps()
        $runInBatch(() => {
            _.forOwn(propsUpdateEvents, (propsUpdates, controllerId) => {
                const resolvedProps = _.reduce(propsUpdates, (acc, {events, wixCodeProps}) => ({
                    events: events ? _.defaults(events, acc.events) : acc.events || {},
                    wixCodeProps: wixCodeProps ? _.defaults(wixCodeProps, acc.wixCodeProps) : acc.wixCodeProps || {}
                }), currentProps[controllerId] || {})

                setControllerProps(controllerId, resolvedProps)
            })

            setPendingControllersPropsUpdates(null)
        })
    }),
    sendUpdateConfigMessagesToWorker: (sendMessageToWorker, updateConfigMessages) => {
        _.forEach(updateConfigMessages, message => sendMessageToWorker('on_controller_config_update', message))
    },
    getEditorIndex: colorName => Number(colorName.split('_').pop()),
    capitalize: v => _.capitalize(v),
    boltComponents: withActions((actions, compId) => ({
        Effect: withHostContext(Effect, actions.addRuntimeCompAnimationStub, compId)
    })),
    getLazySentry: (controllerId, getSentry, isInSSR) =>
        class LazySentry {
            constructor(options) {
                this.options = options
                this.scopes = []
            }

            captureException(...args) {
                if (isInSSR) {
                    const sentry = getSentry()
                    if (!this.nodeClient) {
                        this.nodeClient = new sentry.Hub(new sentry.NodeClient(this.options))
                        _.forEach(this.scopes, fn => {this.nodeClient.configureScope(fn)})
                    }
                    this.nodeClient.captureException(...args)
                } else {
                    let sentry = getSentry()
                    sentry.onLoad(() => {
                        sentry = getSentry()
                        if (!this.browserClient) {
                            this.browserClient = new sentry.Hub(new sentry.BrowserClient(this.options))
                            _.forEach(this.scopes, fn => {this.browserClient.configureScope(fn)})
                        }
                        this.browserClient.captureException(...args)
                    })
                    sentry.forceLoad()
                }
            }

            configureScope(fn) {
                const client = isInSSR ? this.nodeClient : this.browserClient
                if (client) {
                    client.configureScope(fn)
                } else {
                    this.scopes.push(fn)
                }
            }
        }
}

function withHostContext(Component, addRuntimeCompAnimationStub, compId) {
    function WithHostContext(props) {
        return <Component compId={compId} addRuntimeCompAnimationStub={addRuntimeCompAnimationStub} {...props} />
    }

    WithHostContext.displayName = `withHostContext(${Component.displayName || Component.name}`
    return WithHostContext
}

export const defaultModel = {
    data: {},
    props: {}
}

