import {COOKIES, DIALOGS, NOTIFICATIONS} from '../constants'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import _ from 'lodash'

const showNotification = ({setDialogOptions}, notificationType, languageToDisplay) => {
    setDialogOptions({
        dialogType: DIALOGS.Notification,
        notificationType,
        ...languageToDisplay ? {languageToDisplay} : {}
    })
}

const revokeSocialAppPermission = (contentWindow, type) => {
    contentWindow.postMessage({
        src: 'wix-social-login',
        type,
        action: 'revoke'
    }, '*')
}

const onDialogError = (dialog, errorCode, isOAuth = false) => {
    dialog.endLoader()
    if (isOAuth) {
        dialog.setOuathErrorMessageByCode(errorCode)
    } else {
        dialog.setErrorMessageByCode(errorCode)
    }
    if (dialog.captchaReset) {
        dialog.captchaReset()
    }
}

const onDialogSuccess = ({setDialogOptions}, dialog, dialogOptions, member) => {
    if (dialog.captchaReset) {
        dialog.captchaReset()
    }
    if (dialogOptions && dialogOptions.successCallback) {
        dialogOptions.successCallback(member)
    }
    dialog.endLoader()
    setDialogOptions(null)
}

const clearServerCookie = ({name, path}) => {
    document.cookie = `${name}=;max-age=0`
    document.cookie = `${name}=;max-age=0;path=${path}`
}

export default {
    closeDialog: withActions(({setDialogOptions}, {completeWithNavigation, resetNextPageNavigationInfo, reportSmBi, dialogOptions}, dialog, authSuccess, isUserAction) => {
        if (isUserAction) {
            reportSmBi('SITE_MEMBER_EXIT_DIALOG', {context: dialog.props.biContext || dialog.props.id})
        }
        if (dialogOptions && dialogOptions.cancelCallback) {
            dialogOptions.cancelCallback()
        }
        resetNextPageNavigationInfo()
        completeWithNavigation()
        setDialogOptions(null)
    }),
    closeWelcomeDialog: withActions((context, {closeDialog, mainPagePath, deleteCookieFn, hostName}, ...args) => {
        closeDialog(...args)
        hostName = hostName.indexOf('www') === 0 ? hostName.substr(3) : hostName
        deleteCookieFn({name: COOKIES.SM_EF, domain: hostName, path: mainPagePath})
        deleteCookieFn({name: COOKIES.SM_EF, domain: hostName, path: '/'})
        clearServerCookie({name: COOKIES.SM_EF, path: mainPagePath})
    }),
    handleRegister: ({handleRegisterSuccess, collectionId, metaSiteId, appUrl, svSession, register}, registerData, onSuccess, onError) => {
        const data = {
            method: 'POST',
            body: {
                svSession,
                collectionId,
                metaSiteId,
                appUrl,
                ...registerData
            }
        }

        register(data, payload => {
            const {payload: {siteMemberDto: member, sessionToken, emailVerified, status: memberStatus, email}} = payload
            handleRegisterSuccess({
                emailVerified,
                member,
                email,
                memberStatus,
                sessionToken
            }, onSuccess, onError, payload.payload)
        }, onError)
    },
    handleToken: ({handleRegisterSuccess, svSession, biVisitorId, handleOauthToken}, submitData, postMessageTarget, onSuccess, onError) => {
        const data = {
            method: 'POST',
            body: {
                svSession,
                visitorId: biVisitorId,
                ...submitData
            }
        }

        handleOauthToken(data, payload => {
            revokeSocialAppPermission(postMessageTarget, submitData.provider)
            const {payload: {siteMemberDto: member, smSession}} = payload
            const sessionToken = smSession && smSession.sessionToken
            handleRegisterSuccess({
                emailVerified: true,
                member,
                memberStatus: member.status,
                email: member.email,
                sessionToken
            }, onSuccess, onError, payload.payload)
        }, err => {
            revokeSocialAppPermission(postMessageTarget, submitData.provider)
            onError(err)
        })
    },
    handleWelcomeSubmit: ({appPages, navigateToPage}, data, dialog) => {
        dialog.closeDialog(false)
        const page = _.find(appPages, {tpaPageId: 'member_info'})
        if (page) {
            navigateToPage({pageId: page.id})
        }
    },
    changePasswordHandler: ({resetMemberPassword, onPasswordChangeSuccess, reportSmBi}, submitData, dialog) => {
        reportSmBi('SITE_MEMBER_SUBMIT_BUTTON', {context: dialog.props.id})
        const data = {
            method: 'POST',
            body: submitData
        }
        resetMemberPassword(data, () => onPasswordChangeSuccess(), dialog.setErrorMessageByCode)
    },
    onPasswordChangeSuccess: withActions((actions, {language, navigationInfo, navigateToPage}) => {
        const {queryParams} = navigationInfo
        const newQueryParams = _.clone(queryParams)
        delete newQueryParams.forgotPasswordToken
        navigateToPage(navigationInfo)
        showNotification(actions, NOTIFICATIONS.ResetPasswordNewPassword, language)
    }),
    onCaptchaDialogClosed: withActions(({setDialogOptions}, {dialogOptions}) => {
        setDialogOptions(null)
        dialogOptions.onClose()
    }),
    onCaptchaDialogVerified: withActions(({setDialogOptions}, {dialogOptions}, token) => {
        setDialogOptions(null)
        dialogOptions.onSuccess(token)
    }),
    onRegisterCallback: withActions(({setDialogOptions, setMemberDetails}, {handleRegister, dialogOptions, reportSmBi, interactionStarted, trackEvent}, registerData, dialog) => {
        interactionStarted()
        trackEvent('CustomEvent', {
            eventCategory: 'Site members',
            eventAction: 'Sign up Submit',
            eventLabel: 'Wix'
        })
        reportSmBi('SITE_MEMBER_SUBMIT_BUTTON', {context: dialog.props.id})
        dialog.startLoader()
        const onError = errorCode => {
            onDialogError(dialog, errorCode)
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Sign up Failure',
                eventLabel: 'Wix'
            })
        }

        const onSuccess = member => {
            setMemberDetails(member)
            onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Sign up Success',
                eventLabel: 'Wix'
            })
        }
        const dialogData = {
            lang: dialog.props.language,
            privacyStatus: dialog.getJoinCommunityStatus()
        }
        handleRegister({...registerData, ...dialogData}, onSuccess, onError)
    }),
    // NOTE: In case of using wixui.SocialAuth component we wont have dialog object
    tokenHandler: withActions(({setDialogOptions, setMemberDetails}, {handleToken, dialogOptions, interactionStarted, trackEvent}, token, provider, postMessageTarget, dialog) => {
        interactionStarted()
        trackEvent('CustomEvent', {
            eventCategory: 'Site members',
            eventAction: 'Log in Submit',
            eventLabel: provider
        })
        if (dialog) {
            dialog.startLoader()
        }
        const onError = errorCode => {
            if (dialog) {
                onDialogError(dialog, errorCode, true)
            }
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Log in Failure',
                eventLabel: provider
            })
        }

        const onSuccess = member => {
            setMemberDetails(member)
            if (dialog) {
                onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
            }
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Log in Success',
                eventLabel: provider
            })
        }

        const submitData = {
            token,
            provider
        }
        if (dialog) {
            Object.assign(submitData, {
                mode: dialog.props.id,
                lang: dialog.props.language,
                privacyStatus: dialog.getJoinCommunityStatus()
            })
        }
        handleToken(submitData, postMessageTarget, onSuccess, onError)
    }),
    // NOTE: In case of using wixui.SocialAuth component we wont have dialog object
    onBackendSocialLogin: withActions(({setMemberDetails, setDialogOptions}, {handleRegisterSuccess, dialogOptions, interactionStarted, trackEvent}, dialog, postMessageTarget, vendor, submitData) => {
        interactionStarted()
        trackEvent('CustomEvent', {
            eventCategory: 'Site members',
            eventAction: 'Log in Submit',
            eventLabel: vendor
        })
        const onError = errorCode => {
            if (dialog) {
                onDialogError(dialog, errorCode, true)
            }
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Log in Failure',
                eventLabel: vendor
            })
        }

        const onSuccess = member => {
            setMemberDetails(member)
            if (dialog) {
                onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
            }
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Log in Success',
                eventLabel: vendor
            })
        }

        const {smSession, siteMemberDto: member} = submitData
        const sessionToken = smSession && smSession.sessionToken
        handleRegisterSuccess({
            emailVerified: true,
            member,
            memberStatus: member.status,
            email: member.email,
            sessionToken
        }, onSuccess, onError, submitData)
    }),
    onLoginCallback: withActions(({setDialogOptions}, {handleLogin, memberLoginCallbacks, dialogOptions, reportSmBi, interactionStarted, trackEvent}, loginData, dialog) => {
        interactionStarted()
        trackEvent('CustomEvent', {
            eventCategory: 'Site members',
            eventAction: 'Log in Submit',
            eventLabel: 'Wix'
        })
        const onError = errorCode => {
            onDialogError(dialog, errorCode)
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Log in Failure',
                eventLabel: 'Wix'
            })
        }
        const onSuccess = member => {
            memberLoginCallbacks.forEach(cb => cb())
            onDialogSuccess({setDialogOptions}, dialog, dialogOptions, member)
            trackEvent('CustomEvent', {
                eventCategory: 'Site members',
                eventAction: 'Log in Success',
                eventLabel: 'Wix'
            })
        }

        reportSmBi('SITE_MEMBER_SUBMIT_BUTTON', {context: dialog.props.id})
        dialog.startLoader()
        handleLogin(loginData, ({member}) => onSuccess(member), onError)
    }),
    forgotPasswordMailHandler: withActions((context, {collectionId, dialogOptions, metaSiteId, appUrl, sendForgotPasswordMail, interactionStarted, interactionEnded}, submitData, dialog) => {
        interactionStarted()
        const data = {
            method: 'POST',
            body: {
                returnUrl: appUrl,
                collectionId,
                metaSiteId,
                lang: dialog.props.language,
                email: submitData.email
            }
        }
        const onSuccess = () => {
            if (dialogOptions && dialogOptions.successCallback) {
                dialogOptions.successCallback()
            }
            showNotification(context, NOTIFICATIONS.ResetPasswordEmail, dialog.props.language)
            interactionEnded()
        }
        sendForgotPasswordMail(data, onSuccess, dialog.setErrorMessageByCode)
    }),
    passwordProtectedServerHandler: withActions(({setPageJsonFileName}, {approvePasswordProtectedPage, validatePassword, metaSiteId, siteId, dialogOptions}, submitData, dialog) => {
        if (_.isEmpty(submitData.password)) {
            dialog.setErrorMessage('PasswordLogin_Wrong_Password')
            return
        }

        const onSuccess = ({payload}) => {
            if (payload.urls[0]) {
                const pageJsonFileName = _.last(payload.urls[0].split('.json')[0].split('\/'))
                setPageJsonFileName(dialogOptions.nextPageId, `${pageJsonFileName}.json`)
            }
            dialog.endLoader()
            approvePasswordProtectedPage()
        }

        const onError = errorCode => {
            dialog.endLoader()
            dialog.setErrorMessageByCode(errorCode || 'SMForm_Error_General_Err')
        }

        const data = {
            method: 'POST',
            body: {
                password: submitData.password,
                metaSiteId,
                siteId,
                pageId: dialogOptions.nextPageId
            }
        }
        validatePassword(data, onSuccess, onError)
    }),
    passwordProtectedClientHandler: ({approvePasswordProtectedPage, pageSecurityData}, submitData, dialog) => {
        if (_.isEmpty(submitData.password)) {
            dialog.setErrorMessage('PasswordLogin_Wrong_Password')
        } else if (pageSecurityData.passwordDigest === submitData.password) {
            dialog.endLoader()
            approvePasswordProtectedPage()
        } else {
            dialog.endLoader()
            dialog.setErrorMessageByCode('SMForm_Error_General_Err')
        }
    },
    approvePasswordProtectedPage: withActions(({setDialogOptions, setApprovedPasswordPage}, {navigateToPage, dialogOptions, registerToNavigationEnd}) => {
        registerToNavigationEnd(() => setDialogOptions(null))
        if (dialogOptions && dialogOptions.nextPageId) {
            setApprovedPasswordPage(dialogOptions.nextPageId, true)
            navigateToPage(dialogOptions.nextNavigationInfo ? dialogOptions.nextNavigationInfo : {pageId: dialogOptions.nextPageId})
        }
    }),
    resendEmailVerification: withActions(({switchDialogType}, {reportSmBi, biContext, resendEmailVerification}) => {
        reportSmBi('SITE_MEMBER_CLICK_LINK', {context: biContext, name: 'resend confirmation email'})
        resendEmailVerification()
        switchDialogType(DIALOGS.SentConfirmationEmail)
    }),
    onCloseSuccessNotification: withActions(({setDialogOptions}, {reportSmBi}) => {
        const notificationId = 'resetPasswordSuccessNotification'
        setDialogOptions({dialogType: DIALOGS.Login})
        reportSmBi('SITE_MEMBER_SUBMIT_BUTTON', {context: notificationId})
    }),
    handleRegisterSuccess: ({showEmailVerificationDialog, showApplyForMembershipDialog, applySessionToken, interactionEnded}, {emailVerified, member, memberStatus, email, sessionToken}, onSuccess, onError, payload) => {
        const pendingStatus =
            showEmailVerificationDialog({
                emailVerified,
                member,
                sessionToken
            }) ||
            showApplyForMembershipDialog({
                memberStatus,
                email,
                onSuccess,
                memberData: member || payload
            })

        if (pendingStatus) {
            interactionEnded()
        } else if (sessionToken) {
            applySessionToken(sessionToken, onError, () => {
                onSuccess(member)
                interactionEnded()
            })
        }
    }
}
