import _ from 'lodash'
import {
    clearCompMetadata,
    getCompMetadata,
    getDescriptor,
    setCompFailedRequests,
    setCompMetadata
} from './wixappsDataHandler'

export default function transformAndSetMetaData(transformFunc, appPartDrcAPI, packageName, compId, responseData, currentValue) {
    setCompFailedRequests(false, appPartDrcAPI, packageName, compId)

    const updatedMetadata = {dataReady: true}
    const descriptor = getDescriptor(appPartDrcAPI, packageName)
    const metadata = getCompMetadata(appPartDrcAPI, packageName, compId)
    if (descriptor && (!_.has(metadata, 'videos') || metadata.videos === 0)) {
        clearCompMetadata(appPartDrcAPI, packageName, compId)
    } else {
        setCompMetadata(updatedMetadata, appPartDrcAPI, packageName, compId)
    }

    transformFunc(responseData, currentValue, appPartDrcAPI)

    return currentValue
}
