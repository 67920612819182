import _ from 'lodash'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {getBundledPartsDataRequest} from './dataRequirementChecker/builderDataHandler'
import {objectUtils} from 'santa-core-utils'
import {getPartViews} from '../../utils/listBuilder/appRepo'

export const name = 'ListBuilderAspect'

export const defaultModel = {
    requestFailed: false,
    warmup: {},
    metadata: {
        items: {}
    },
    appPartCounters: {},
    listBuilderFetchFn: null,
    isAfterFetchRegistration: false
}

const markItemsState = (items, state, setAppPartItemRequestsMetadata) => {
    _.forEach(items, appPartName => {
        setAppPartItemRequestsMetadata(appPartName, state)
    })
}

const onAppPartFetchSuccess = ({$runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, requestDescriptor, getWixAppsStore, setWixAppsPackage, setAppPartCounters}, response) => {
    const partialWixappsStore = {
        wixapps: {
            appbuilder: objectUtils.cloneDeep(getWixAppsStore().appbuilder)
        }
    }
    const requestAppPartIds = requestDescriptor.data.appPartIds

    if (!response) {
        $runInBatch(() => {
            markItemsState(requestDescriptor.data.appPartIds, 'error', setAppPartItemRequestsMetadata)
            setRequestFailed(true)
        })
        return
    }

    const currentValue = _.get(partialWixappsStore, requestDescriptor.destination, {})
    const value = requestDescriptor.transformFunc(response, currentValue)
    $runInBatch(() => {
        _.set(partialWixappsStore, requestDescriptor.destination, value)
        setWixAppsPackage('appbuilder', partialWixappsStore.wixapps.appbuilder)
        setAppPartCounters()
        markItemsState(requestAppPartIds, 'loaded', setAppPartItemRequestsMetadata)
    })
}

const onAppPartFetchError = ($runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, appPartIds) => {
    $runInBatch(() => {
        markItemsState(appPartIds, 'error', setAppPartItemRequestsMetadata)
        setRequestFailed(true)
    })
}

const getMostRecentlyUpdatedItem = items => _.chain(items)
    .sortBy(item => Date.parse(item._updatedAt))
    .last()
    .get('_iid')
    .value()

export const functionLibrary = {
    createListBuilderFetch: fetchFn => (requestDescriptor, fetchOptions, onSuccess, onError) => {
        fetchFn(
          requestDescriptor.url,
          fetchOptions,
          'json',
          onSuccess,
          onError
        )
    },
    fetchListBuilderData: withActions(({$runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, setAppPartCounter}, appPartsToFetch, fetchParams) => {
        if (_.isEmpty(appPartsToFetch)) {
            return
        }

        const {getListBuilderDrcAPI, appService, getWixAppsStore, setWixAppsPackage, listBuilderFetchFn, appPartCounters} = fetchParams
        const requestDescriptor = getBundledPartsDataRequest(getListBuilderDrcAPI(), appService, appPartsToFetch)
        const fetchOptions = {
            method: 'POST',
            body: JSON.stringify(requestDescriptor.data),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const currentAppPartCounters = _(appPartsToFetch)
          .keyBy()
          .mapValues(partName => _.get(appPartCounters, partName, 1))
          .value()

        const setAppPartCounters = () => {
            _.forEach(currentAppPartCounters, (count, partName) => setAppPartCounter(partName, count + 1))
        }

        const onSuccess = response => onAppPartFetchSuccess({
            $runInBatch,
            setRequestFailed,
            setAppPartItemRequestsMetadata,
            requestDescriptor,
            getWixAppsStore,
            setWixAppsPackage,
            setAppPartCounters
        }, response)

        const onError = () => onAppPartFetchError($runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, requestDescriptor.data.appPartIds)
        listBuilderFetchFn(requestDescriptor, fetchOptions, onSuccess, onError)
    }),
    getDataSelectorForUnsupportedType: (logicalTypeName, captureError) => {
        captureError(new Error(`Invalid selector type - ${logicalTypeName}`))
        return null
    },
    getPartViews,
    getMostRecentlyUpdatedItem,
    getAppbuilderDataByPath: (getDataByPath, path/*, counter*/) => getDataByPath('appbuilder', path)
}

export const init = ({setIsAfterFetchRegistration}) => {
    setIsAfterFetchRegistration(true)
}
