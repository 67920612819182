import _ from 'lodash'
import {urlUtils} from 'santa-core-utils'

const addAppSectionParamsToNavInfo = (navInfo, appSectionParams = {}) => {
    if (!_.isEmpty(appSectionParams)) {
        try {
            navInfo.queryParams = {appSectionParams: JSON.stringify(appSectionParams)}
            navInfo.hasAppSectionParams = true
        } catch (ex) { // bummer
        }
    }

    return navInfo
}

const isRgbaColor = color => {
    if (!_.startsWith(color, 'rgba')) {
        return false
    }

    const split = _(color)
        .replace('rgba', '')
        .replace('(', '')
        .replace(')', '')
        .split(',')

    if (split.length !== 4) {
        return false
    }

    const validRgb = _.every(split.slice(0, 3), number => {
        const isRgbValid = number >= 0 && number <= 255
        const percentage = parseFloat(number)
        const isPercent = !isNaN(percentage) && percentage > 0 && percentage <= 100

        return isRgbValid || isPercent
    })

    if (!validRgb) {
        return false
    }

    const alpha = _.last(split)

    if (alpha) {
        return alpha >= 0 && alpha <= 1
    }

    return true
}

export const navigationHandlersFunctionLibrary = {
    toQueryString: urlUtils.toQueryString,
    addAppSectionParamsToNavInfo,
    isRgbaColor,
    buildNavigateToSectionPageNavInfo({pageId, state, appSectionParams, noTransition, ignorePageUriSeo, pageBackgroundColorOverride}) {
        const navInfo = addAppSectionParamsToNavInfo({
            pageId,
            tpaInnerRoute: state
        }, appSectionParams)

        if (noTransition) {
            navInfo.transition = 'none'
        }
        if (ignorePageUriSeo) {
            navInfo.ignorePageUriSeo = true
        }
        if (pageBackgroundColorOverride) {
            navInfo.pageBackgroundColorOverride = pageBackgroundColorOverride
        }
        return navInfo
    },
    setSectionStateAndQueryParams(
        sectionReactComp,
        shouldRefreshIframe,
        sectionCompId,
        state,
        appSectionParams
    ) {
        if (sectionCompId && sectionReactComp) {
            if (shouldRefreshIframe) {
                sectionReactComp.setState({
                    sectionUrlState: state
                })
                sectionReactComp.setState({
                    sectionUrlParams: appSectionParams
                })
            } else {
                // no solution implemented for queryParams
                sectionReactComp.setState({
                    pushState: state
                })
                sectionReactComp.reportStateChanged(state)
            }
        }
    },
    navigateWithoutClosingPopupIfPossible(
        navInfo,
        primaryPageId,
        onComplete,
        navigateToPage,
        shouldScrollToTop
    ) {
        if (navInfo.pageId === primaryPageId) {
            navInfo.shouldDisableScrollToTop = !shouldScrollToTop
            navigateToPage(navInfo)
        } else {
            navigateToPage(navInfo)
        }

        if (_.isFunction(onComplete)) {
            onComplete()
        }
    }
}
