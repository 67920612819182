import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {guidUtils} from 'santa-core-utils'
import {COMP_TYPES} from '../constants'
import _ from 'lodash'
import $ from 'zepto'
import reactDOM from 'react-dom'

const POPUP_PRFIX = 'tpaPopup-'

function getPopupStructure() {
    return {
        componentType: COMP_TYPES.TPA_POPUP,
        type: 'Component',
        id: guidUtils.getUniqueId(POPUP_PRFIX),
        skin: 'wysiwyg.viewer.skins.TPAPopupSkin',
        styleId: ''
    }
}

export const popupFunctionLibrary = {
    getUniqueId: guidUtils.getUniqueId,
    showPopup: withActions((aspectActions, popupData = {}, popupOnClose) => {
        const structure = getPopupStructure()
        const popup = {structure, popupData, popupOnClose}
        aspectActions.setPopup(structure.id, popup)
    }),
    removePopup: withActions((aspectActions, compId) => {
        aspectActions.setPopup(compId, undefined)
    }),
    getPopupsCompData: withActions((aspectActions, allPopupsData) => allPopupsData),
    removePopups: withActions((aspectActions, getPopupsToRemove) => {
        const popups = getPopupsToRemove()
        _.forEach(popups, popup => {
            aspectActions.setPopup(popup.structure.id, undefined)
        })
    }),
    handlerOpenPopup: (showPopupFn, popupData) => new Promise(resolve => {
        showPopupFn(popupData, resolve)
    }),
    getIntValue: val => {
        if (_.isString(val)) {
            const x = parseInt(val, 10)
            if (!_.isNaN(x)) {
                return x
            }
            return 0
        } else if (_.isNumber(val)) {
            return val
        }
        return 0
    },
    getOrigCompStyle: reactComp => {
        let origCompStyle = {}
        const node = reactDOM.findDOMNode(reactComp) //eslint-disable-line react/no-find-dom-node
        if (node) {
            origCompStyle = $(node).offset()
            const clientRect = node.getBoundingClientRect()
            origCompStyle.actualTop = clientRect.top
            origCompStyle.actualLeft = clientRect.left
        }
        return origCompStyle
    }
}
