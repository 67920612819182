import {NULL_RETURN_VALUE} from '../constants'
import _ from 'lodash'

function sendSiteMemberDataToWidget(getMemberDetailsFn, compRef) {
    return new Promise(resolve => {
        getMemberDetailsFn(memberData => {
            if (memberData !== null) {
                resolve(memberData)
                if (compRef) {
                    compRef.setSiteMemberDataState(null)
                }
            } else if (compRef) {
                compRef.setSiteMemberDataState({callback: resolve})
            } else {
                resolve(null)
            }
        })
    })
}

export const siteMembersHandlersFunctionLibrary = {
    refreshCurrentMember: (isLoggedIn, getMemberDetails) => {
        if (isLoggedIn) {
            const onSuccess = null
            const onError = null
            const refreshCurrentMember = true
            getMemberDetails(onSuccess, onError, refreshCurrentMember)
        }
        return null
    },
    tpaAuthorizeMemberPages: (isLoggedIn, authorizeMemberPagesFn, error) => new Promise(resolve => {
        if (isLoggedIn) {
            authorizeMemberPagesFn(resolve, () => {
                resolve(error)
            })
        } else {
            resolve(null)
        }
    }),
    smCurrentMember: (isLoggedIn, getMemberDetailsFn, compRef, hidePii) => {
        if (isLoggedIn) {
            return sendSiteMemberDataToWidget(getMemberDetailsFn, compRef).then(data => {
                if (data === null) {
                    return NULL_RETURN_VALUE
                }

                return hidePii ? {
                    id: data.id,
                    owner: data.owner,
                    status: data.status
                } : data
            })
        }
        return Promise.resolve(NULL_RETURN_VALUE)
    },
    openCaptchaDialog: (language, showCaptchaDialogFn) => new Promise(resolve => {
        showCaptchaDialogFn({
            language,
            onSuccess: token => resolve(token),
            onClose: () => resolve(null)
        })
    }),
    smRequestLogin: (isLoggedIn, getMemberDetailsFn, compRef, event, showAuthenticationDialogFn, appDefId) => new Promise(resolve => {
        if (isLoggedIn) {
            sendSiteMemberDataToWidget(getMemberDetailsFn, compRef).then(memberData => {
                resolve({
                    authResponse: true,
                    data: memberData
                })
            })
        } else {
            const mode = _.get(event, 'data.mode')
            const showLoginDialog = mode && mode === 'login'
            const language = _.get(event, 'data.language')
            let cancelCallback
            if (_.get(event, 'data.callOnCancel')) {
                cancelCallback = () => {
                    resolve({
                        wasCancelled: true
                    })
                }
            }
            const successCallback = memberData => {
                resolve({
                    authResponse: true,
                    data: memberData
                })
            }

            showAuthenticationDialogFn({
                compId: event.compId,
                successCallback,
                cancelCallback,
                showLoginDialog,
                appId: appDefId,
                checkCommunityCheckbox: _.get(event, 'data.checkCommunityCheckbox'),
                ...language ? {language} : {}
            })
        }
    }),
    logOutCurrentMember: (isLoggedIn, logoutFn, language, error, _id, navigateToPage) => {
        if (isLoggedIn) {
            if (navigateToPage) {
                logoutFn(language, undefined, undefined, {navigateToPage})
            } else {
                logoutFn(language)
            }

            return null
        }
        return error
    }
}
