import {withActions} from 'carmi-host-extensions'

export const name = 'InteractionsBehaviorsAspect'

export const functionLibrary = {
    toggleVariant: withActions((actions, activeVariants, setVariantForComp, behavior) => {
        function removeClickListener() {
            document.removeEventListener('click', outsideClickListener)
        }

        // this behavior makes sense for click interaction, need to generalize it for other sources (velo, viewport enter / leave)
        function outsideClickListener(event) {
            const origEl = document.getElementById(`${behavior.targetId}`)
            if (!origEl.contains(event.target)) {
                setVariantForComp(behavior.targetId, undefined)
                removeClickListener()
            }
        }
    
        
        if (activeVariants[behavior.targetId] !== behavior.params.variantId) { // variant is not currently active
            setVariantForComp(behavior.targetId, behavior.params.variantId)
            if (behavior.params.toggleOff === 'blur') {
                    document.addEventListener('click', outsideClickListener)
            }
            return
        }
        // variant is already active
        if (behavior.params.toggleOff === 'secondClick') {
            setVariantForComp(behavior.targetId, undefined)
        }
    })
}