import {CLASSICS_EVENTS} from './appPartBiConstants'

const _ = require('lodash')

function reportError(reportBI, errorDefaultValues, errorData, params) {
    try {
        const error = _.defaults({}, errorData, errorDefaultValues)
        reportBI(error, params)
    } catch (e) {} //eslint-disable-line no-empty
}

function reportEvent(reportBI, eventDefaultValues, eventData, params) {
    try {
        const event = _.defaults({}, eventData, eventDefaultValues)
        reportBI(event, params)
    } catch (e) {
        // empty
    }
}

const hasFiredFirstTimeRenderStarted = {} // eslint-disable-line @wix/santa/no-module-state
const hasFiredFirstTimeRenderFinished = {} // eslint-disable-line @wix/santa/no-module-state

function reportFirstTimeRenderStart(reportBI, eventDefaultValues, isViewerMode, siteId, compId, appPartName) {
    if (!isViewerMode) {
        return
    }

    if (!hasFiredFirstTimeRenderStarted[compId]) {
        reportEvent(reportBI, eventDefaultValues, CLASSICS_EVENTS.FIRST_TIME_APP_PART_RENDER_START, {
            component_id: compId,
            app_part_name: appPartName,
            site_id: siteId
        })

        hasFiredFirstTimeRenderStarted[compId] = true
    }
}

function reportFirstTimeRenderFinish(reportBI, eventDefaultValues, isViewerMode, siteId, compId, appPartName, state, duration, renderDuration) {
    if (!isViewerMode) {
        return
    }

    if (hasFiredFirstTimeRenderStarted[compId] && !hasFiredFirstTimeRenderFinished[compId]) {
        reportEvent(reportBI, eventDefaultValues, CLASSICS_EVENTS.FIRST_TIME_APP_PART_RENDER_FINISH, {
            component_id: compId,
            app_part_name: appPartName,
            app_part_state: state,
            duration,
            render_duration: renderDuration,
            site_id: siteId
        })

        hasFiredFirstTimeRenderFinished[compId] = true
    }
}

export {
    reportError,
    reportEvent,
    reportFirstTimeRenderStart,
    reportFirstTimeRenderFinish
}
