import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'
import {RemoteRefDeadComp} from 'bolt-components'
import * as warmupUtils from 'warmupUtils' //eslint-disable-line import/no-unresolved

export const name = 'RemoteRefsCompsAspect'

const getDeadCompStructureAndData = pageId => ({
    structure: {
        [RemoteRefDeadComp.compType]: {
            componentType: RemoteRefDeadComp.compType,
            id: RemoteRefDeadComp.compType,
            metaData: {pageId},
            layout: {}
        }
    },
    data: {}
})

export const defaultModel = {
    fetchedRemoteRefs: {},
    resolvedRefs: {},
    remoteStructures: {mobile: {}, desktop: {}}
}

const fixStructureAndData = (page, pageId, flattenStructure) => {
    const structure = flattenStructure(page.structure)
    const data = _.mapValues(page.data, dataMap =>
        _.mapValues(dataMap, dataItem => _.defaults({metaData: {...dataItem.metaData, pageId}}, dataItem)))
    return {...page, structure, data}
}

const markRefsAsResolved = (ids, rootId, setTemplateRef, setResolvedRef) => {
    _.forEach(ids, compId => {
        setTemplateRef(compId, rootId)
        setResolvedRef(compId, rootId)
    })
}

const resolveRemoteRefs = (
    {
        boltInstance,
        addPageStructureAndData,
        setResolvedRef,
        setTemplateRef,
        setFetchedRemoteRefs,
        setRemoteStructures,
        structureAndDataToAdd,
        rootId,
        compIds
    }) => {
    boltInstance.$runInBatch(() => {
        addPageStructureAndData(structureAndDataToAdd, rootId, boltInstance)
        markRefsAsResolved(compIds, rootId, setTemplateRef, setResolvedRef)

        setFetchedRemoteRefs(rootId)
        setRemoteStructures(rootId, structureAndDataToAdd)
    })
}

const REMOTE_REF_SITE_ASSETS_FETCH_TAG = 'remote-refs-site-assets'
const REMOTE_REF_SITE_ASSETS_RESOLVE_STRUCTURE_TAG = 'remote-refs-site-assets-resolve-structure'

export const functionLibrary = {
    parseRemoteUrl: url => url && warmupUtils.urlUtils.parseUrl(url),
    fetchRemoteRef: withActions((boltInstance, params) => {
        const {
            getFetchedRootId,
            primaryPageId,
            flattenStructure,
            addPageStructureAndData,
            fetch,
            compIds,
            captureError,
            setTemplateRef,
            setResolvedRef,
            setFetchedRemoteRefs,
            setRemoteStructures,
            interactionStartedFn,
            interactionEndedFn
        } = params

        const onSuccess = page => {
            interactionEndedFn(REMOTE_REF_SITE_ASSETS_FETCH_TAG)
            interactionStartedFn(REMOTE_REF_SITE_ASSETS_RESOLVE_STRUCTURE_TAG)

            const rootId = _.get(page.structure, 'id')
            const structureAndDataToAdd = fixStructureAndData(page, primaryPageId, flattenStructure)
            resolveRemoteRefs({
                boltInstance,
                addPageStructureAndData,
                setResolvedRef,
                setTemplateRef,
                setFetchedRemoteRefs,
                setRemoteStructures,
                structureAndDataToAdd,
                rootId,
                compIds
            })

            interactionEndedFn(REMOTE_REF_SITE_ASSETS_RESOLVE_STRUCTURE_TAG)
        }

        const onError = error => {
            const deadCompStructureAndData = getDeadCompStructureAndData(primaryPageId)
            resolveRemoteRefs({
                boltInstance,
                addPageStructureAndData,
                setResolvedRef,
                setTemplateRef,
                setFetchedRemoteRefs,
                setRemoteStructures,
                structureAndDataToAdd: deadCompStructureAndData,
                rootId: RemoteRefDeadComp.compType,
                compIds
            })

            //report error count to see if it matches with failure percentage
            interactionStartedFn(`${REMOTE_REF_SITE_ASSETS_FETCH_TAG}-error`)

            captureError(error, {tags: {description: 'Failed to fetch remoteRef data'}})
        }

        if (getFetchedRootId()) { // Case we already fetched for the remote we only need to setTemplateRef
            markRefsAsResolved(compIds, getFetchedRootId(), setTemplateRef, setResolvedRef)
        } else {
            interactionStartedFn(REMOTE_REF_SITE_ASSETS_FETCH_TAG)
            fetch(onSuccess, onError)
        }
    }),
    resolvedMalformedRefComp: withActions((boltInstance, params) => {
        const {
            primaryPageId,
            addPageStructureAndData,
            compIds,
            captureError,
            setTemplateRef,
            setResolvedRef,
            setRemoteStructures
        } = params
        const deadCompStructureAndData = getDeadCompStructureAndData(primaryPageId)
        resolveRemoteRefs({
            boltInstance,
            addPageStructureAndData,
            setResolvedRef,
            setTemplateRef,
            setFetchedRemoteRefs: _.noop,
            setRemoteStructures,
            structureAndDataToAdd: deadCompStructureAndData,
            rootId: RemoteRefDeadComp.compType,
            compIds
        })
        captureError({}, {tags: {description: 'Malformed refComp data'}})
    }),
    addMissingStructures: withActions((boltInstance, rootId, structureAndDataToAdd, addPageStructureAndData) =>
        addPageStructureAndData(structureAndDataToAdd, rootId, boltInstance))
}
