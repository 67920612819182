import _ from 'lodash'
import {urlUtils} from 'santa-core-utils'
import {getSiteDataDestination} from '../wixappsDataHandler'
import transformAndSetMetaData from '../transformAndSetMetaData'
import transformMediaItemsResponse from '../transformMediaItemsResponse'

function getRelatedPostsUrl(baseUrl, permalink, storeId) {
    if (permalink) {
        return `${baseUrl}/apps/lists/1/posts/permalink/related?permalink=${encodeURIComponent(permalink)}&storeId=${storeId}`
    }

    //Return url to enpoint which return related posts for recent post.
    //So when we open single post page in editor first time we don't have permalink
    //and we use this url (see this bug BLOG-3542).
    return `${baseUrl}/apps/lists/1/posts/related?&storeId=${storeId}`
}

function readRelatedPosts(appPartDrcAPI, compData, appService, params, urlData, defaultOptions) {
    params.collectionId = params.collectionId || defaultOptions.collectionId

    const {languageFromModel, getExternalBaseUrl, videoNotFoundUrl, getExistingRootNavigationInfo} = appPartDrcAPI
    const lang = languageFromModel || 'en'
    const primaryRootInfo = getExistingRootNavigationInfo()
    const pageInfo = urlData ? _.assign(primaryRootInfo, urlData) : primaryRootInfo
    const permalink = pageInfo && pageInfo.pageAdditionalData || ''
    const transformFn = (responseData, currentValue) =>
        transformMediaItemsResponse(compData.id, params.collectionId, {payload: responseData}, currentValue, lang.toLowerCase(), videoNotFoundUrl)

    return [{
        destination: getSiteDataDestination(appService.packageName),
        name: appService.packageName,
        url: getRelatedPostsUrl(urlUtils.baseUrl(getExternalBaseUrl()), permalink, appService.datastoreId),
        transformFunc: transformAndSetMetaData.bind(this, transformFn, appPartDrcAPI, appService.packageName, compData.id)
    }]
}

export {
    readRelatedPosts
}
