import {AnimationsInstance, BrowserFlags} from 'common-types'
import {aspectNames} from 'common-types'
import * as _ from 'lodash'
import {withActions} from '../withActions'

export const name = aspectNames.BgScrubAspect
export const DOM_READY_NS = 'domReady'
export const MEASUREMENTS_NS = 'measurements'

export interface ScrubArgs {
    compId: string
    element: Element
    name: string

    duration: number
    delay: number

    params: {
        browserFlags: BrowserFlags,
        viewPortHeight: number
        componentHeight: number,
        componentTop: number
    }
}

export interface BgScrubStore {
    domReady: boolean,
    measurements: Record<string, {top: number; height: number}>
}

export const defaultModel = {
    [DOM_READY_NS]: false,
    [MEASUREMENTS_NS]: {}
}

const scrubTickers = new WeakMap()
const sequenceInstances = new WeakMap()
const resizeObserverCache = new WeakMap()
const resizeObserverCleanup = new WeakMap()
const observedElementIds = new Set()

function updateCompMeasurementTop(windowObject, setCompMeasurement, scrollTop, id) {
    const element = windowObject.document.getElementById(id)
    const rect = element.getBoundingClientRect()
    const top = rect.top + scrollTop
    setCompMeasurement(`${id}__top`, top)
}

function updateMeasurementsTop(setCompMeasurement, windowObject) {
    const scrollTop = windowObject.scrollY
    const boundTopSetter = updateCompMeasurementTop.bind(null, windowObject, setCompMeasurement, scrollTop)

    observedElementIds.forEach(boundTopSetter)
}

function getObserver(setCompMeasurement, windowObject, siteContainerId) {
    let observer = resizeObserverCache.get(windowObject)

    if (!observer) {
        observer = new windowObject.ResizeObserver(entries => {
            entries.forEach(entry => {
                if (entry.borderBoxSize && entry.borderBoxSize.length) {
                    setCompMeasurement(`${entry.target.id}__height`, entry.borderBoxSize[0].blockSize)
                }

                if (entry.target.id === siteContainerId) {
                    updateMeasurementsTop(setCompMeasurement, windowObject)
                }
            })
        })

        resizeObserverCache.set(windowObject, observer)

        const siteContainer = windowObject.document.getElementById(siteContainerId)
        observer.observe(siteContainer, {box: 'border-box'})
    }

    return observer
}

export const functionLibrary = {
    scrubCreate(args: ScrubArgs) {
        return {
            name: args.name,
            compId: args.compId,
            sequenceFactory: (instance: AnimationsInstance) => ({
                measures: {
                    height: args.params.componentHeight,
                    top: args.params.componentTop
                },
                sequence:
                    instance.animate(args.name, [args.element], args.duration, args.delay, {
                        ...args.params,
                        suppressReactRendering: false,
                        forgetSequenceOnComplete: false,
                        paused: true
                    })
            })

        }
    },

    initScrubs: withActions(({setCompMeasurement}, scrubs, windowObject, siteContainerId) => {
        if (!windowObject || !scrubs.length) {
            return
        }

        const observer = getObserver(setCompMeasurement, windowObject, siteContainerId)
        const scrollTop = windowObject.scrollY

        scrubs.forEach(scrub => {
            const compId = scrub.compId
            const element = windowObject.document.getElementById(compId)
            observer.observe(element, {box: 'border-box'})

            // sync current top measurement
            updateCompMeasurementTop(windowObject, setCompMeasurement, scrollTop, compId)

            observedElementIds.add(compId)

            resizeObserverCleanup.set(scrub.sequenceFactory, () => {
                observer.unobserve(element)
                observedElementIds.delete(compId)
            })
        })
    }),

    scrubDestroy(sequenceFactory, instance: AnimationsInstance) {
        const sequenceInstance = sequenceInstances.get(sequenceFactory)
        const cleanup = resizeObserverCleanup.get(sequenceFactory)
        if (sequenceInstance) {
            if (cleanup) {
                cleanup()
            }
            sequenceInstances.delete(sequenceFactory)
            instance.kill(sequenceInstance.sequence)
        }
        return null
    },

    scrubProgress(sequenceFactory, instance, {viewPortHeight, isSiteBackground, isVisible}, scrollY) {
        if (!instance) {
            return
        }

        // TODO: should not update if not in viewport
        let sequenceInstance = sequenceInstances.get(sequenceFactory)
        if (!sequenceInstance) {
            sequenceInstance = sequenceFactory(instance)
            sequenceInstances.set(sequenceFactory, sequenceInstance)
        }

        const {
            measures: {top, height},
            sequence
        } = sequenceInstance

        const maxTravel = viewPortHeight + height
        const offset = viewPortHeight - (isSiteBackground ? viewPortHeight : top)

        const pos = Math.max(0, scrollY) + offset
        const progress = maxTravel ? pos / maxTravel : 0
        sequence.progress(progress)
    },

    scrubAdvance(scrubbers, scrollY) {
        scrubbers.forEach(s => s(scrollY))
    },

    scrubTick(advance, windowObject) {
        if (!windowObject || !_.isFunction(windowObject.requestAnimationFrame)) {
            return
        }

        const pending = scrubTickers.get(windowObject)
        if (pending) {
            return
        }

        scrubTickers.set(windowObject, windowObject.requestAnimationFrame(() => {
            advance(windowObject.pageYOffset)
            scrubTickers.delete(windowObject)
        }))
    }
}

export function init({setDOMReady}, {eventsManager, initialData: {onScroll}}) {
    if (onScroll) {
        eventsManager.on('windowScroll', onScroll)
        eventsManager.on('domReady', () => setDOMReady(true))
    }
}
