const thirdPartyAnalytics = require('thirdPartyAnalytics') //eslint-disable-line import/no-unresolved
const _ = require('lodash')
const {withActions} = require('carmi-host-extensions')
const {resolveEventParams} = require('./resolve-event-params')

function getDefaultChannels(props, promoteAnalyticsAdapter) {
    const options = {
        reportType: 'event',
        adapter: 'pa',
        sampleRatio: 0
    }
    return [{
        // Sends events to frog.wix.com
        name: promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
        report: thirdPartyAnalytics.decorateReporter(
            props,
            promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
            params => props.reportBI(options, params)
        )
    }]
}

function getEmbeddedChannels(props) {
    if (typeof window === 'undefined' || !window.promoteAnalyticsChannels) {
        return []
    }

    const embeddedChannels = window.promoteAnalyticsChannels.map(channel => ({
        name: channel.name,
        events: channel.events,
        report: thirdPartyAnalytics.decorateReporter(props, channel.name, channel.report),
        config: channel.config
    }))
    window.promoteAnalyticsChannels = undefined
    return embeddedChannels
}

function getManagedChannels(props, promoteAnalyticsAdapter) {
    const accountNameToChannelData = {
        facebookRemarketing: {
            name: promoteAnalyticsAdapter.channelNames.FACEBOOK_PIXEL,
            report: (eventType, eventName, data, options) => _.isFunction(window.fbq) && window.fbq(eventType, eventName, data || {}, options)
        },
        googleAnalytics: {
            name: promoteAnalyticsAdapter.channelNames.GOOGLE_ANALYTICS,
            report() {
                return _.isFunction(window.ga) && window.ga(...arguments) //eslint-disable-line fp/no-arguments
            }
        },
        googleTagManager: {
            name: promoteAnalyticsAdapter.channelNames.GOOGLE_TAG_MANAGER,
            report: eventParams => _.isArray(window.dataLayer) && window.dataLayer.push(...eventParams)
        },
        yandexMetrika: {
            name: promoteAnalyticsAdapter.channelNames.YANDEX_METRICA,
            report: () => window.ym && _.isFunction(window.ym.hit) && window.ym.hit(props.currentUrl.full)
        }
    }

    const channels = _.filter(accountNameToChannelData, channelData => hasScriptsLoaded(props, channelData.name))
    return channels
}

function hasScriptsLoaded(props, channelName) {
    const loadedScripts = Object.values(props.loadedScripts).map(({name}) => name)
    return loadedScripts.some(scriptName => channelName === scriptName)
}

const initGoogleAnalytics = setIsGoogleAnalyticsInitialized => {
    const isGoogleAnalyticsObjectReady = typeof window.ga === 'function'
    if (isGoogleAnalyticsObjectReady) {
        window.ga('require', 'ec')
        setIsGoogleAnalyticsInitialized(true)
    }
}

const initializeTags = withActions((analyticsActions, analyticsProps = {}, packageProps) => {
    if (!packageProps.promoteAnalyticsAdapter) {
        return
    }

    if (analyticsProps.shouldInitTags) {
        const channels = [...getManagedChannels(analyticsProps, packageProps.promoteAnalyticsAdapter), ...getEmbeddedChannels(analyticsProps)]
        if (channels.length) {
            packageProps.promoteAnalyticsAdapter.api.init(channels)
            if (!analyticsProps.isGoogleAnalyticsInitialized) {
                initGoogleAnalytics(analyticsActions.setIsGoogleAnalyticsInitialized)
            }
        }
        analyticsActions.setShouldInitTags(false)
    }
    if (!analyticsProps.isAnalyticsReady) {
        const {consentPolicyObject} = analyticsProps
        const currentPolicy = consentPolicyObject && consentPolicyObject.getCurrentConsentPolicy().policy
        if (!currentPolicy || currentPolicy.analytics || currentPolicy.advertising) {
            analyticsActions.setIsAnalyticsReady(true)
        }
    }
})

function getEventArguments(props, params, options) {
    const {
        isFBServerEventsAppProvisioned: isFBServerEventsEnabled,
        useStoresPurchaseFBServerEvent,
        useEventsPurchaseFBServerEvent,
        useBookingsPurchaseFBServerEvent,
        visitorId
    } = props

    const eventOptions = {
        ...options,
        reportToManagedChannels: true,
        isFBServerEventsEnabled,
        useStoresPurchaseFBServerEvent,
        useEventsPurchaseFBServerEvent,
        useBookingsPurchaseFBServerEvent
    }
    const hashedParams = resolveEventParams(params)
    const eventParams = {...hashedParams, visitorId}
    return {eventParams, eventOptions}
}

const invokeTrackEvent = (props, eventName, params, options, promoteAnalyticsAdapter, analyticsActions) => {
    const {api, listeners, channelNames} = promoteAnalyticsAdapter || {}
    const {isAdapterInitialized, reportToChannelsOnly, reportToListenersOnly, isAnalyticsReady} = props
    const reportBothListenersAndChannels = !reportToListenersOnly && !reportToChannelsOnly

    if (!api) {
        return
    }

    if (!isAdapterInitialized) {
        const defaultChannels = getDefaultChannels(props, promoteAnalyticsAdapter)
        api.init(defaultChannels)
        api.addListener([listeners[channelNames.WIX_DEVELOPERS_ANALYTICS]], props)
        analyticsActions.setIsAdapterInitialized(true)
    }

    const {eventOptions, eventParams} = getEventArguments(props, params, options)
    
    if (isAnalyticsReady) {
        if (reportBothListenersAndChannels) {
            _.invoke(api, 'trackEvent', eventName, eventParams, eventOptions)
        } else if (reportToChannelsOnly) {
            _.invoke(api, 'trackEventToChannelsOnly', eventName, eventParams, eventOptions)
        } else if (reportToListenersOnly) {
            _.invoke(api, 'trackEventToListenersOnly', eventName, eventParams, eventOptions)
        }
    } else {
        _.invoke(api, 'trackEventToListenersOnly', eventName, eventParams, eventOptions)
    }
}


module.exports = {
    invokeTrackEvent,
    initializeTags
}
