import {fillViewDefMissingIDs} from './viewsUtils'

/**
 * Get the view definition with the given name for the type and format needed.
 * @param {AppRepoDefinition} appRepo
 * @param {string} viewName
 * @param {string} type
 * @param {''|'Mobile'|'*'} formatName
 * @returns {ViewDefinition}
 */
function getViewDef(appRepo, viewName, type, formatName) {
    const idWithFormat = [type, viewName, formatName].join('|')
    const idWithoutFormat = [type, viewName].join('|')

    const viewDef = appRepo.views[idWithFormat] || appRepo.views[idWithoutFormat]
    if (viewDef) {
        // Adds missing ids (i.e. def_0, def_1) to proxies without id or data.
        fillViewDefMissingIDs(viewDef)
    }
    return viewDef
}

function getPartViews(repo, partDefinition, formatName) {
    if (!repo) {
        return null
    }

    if (!partDefinition) {
        return {}
    }

    const compViews = {
        Array: getViewDef(repo, partDefinition.viewName, 'Array', formatName)
    }
    compViews[partDefinition.type] = getViewDef(repo, partDefinition.viewName, partDefinition.type, formatName)
    return compViews
}

export {
    getPartViews
}
