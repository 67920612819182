'use strict'
const _ = require('lodash')

const removeHash = value => value && value.replace('#', '')

// Check if the refArray (Style\Layout) contains item under preset condition.
const isRefArrayUnderPreset = (refArray, variantsData) => refArray.values.some(value => {
    const isVariantRelation = value.type === 'VariantRelation'
    if (!isVariantRelation || !value.variants.length) {
        return false
    }
    const presetCondition = variantsData[removeHash(value.variants[0])]
    return presetCondition && presetCondition.type === 'Preset'
})

// Overrides saved partially resolved. We need to ignore presets resolving for already resolved widgets.
const filterPresets = (presets, resolvedRefArray, presetItemKey) => {
    const variantIds = resolvedRefArray.values.filter(value => value.type === 'VariantRelation').map(variantRelation => removeHash(variantRelation.variants[0] || ''))
    const firstPresetLayerIndex = presets.findIndex(array => array.map(value => value.type === 'VariantRelation' ? value.to : value).some(value => variantIds.includes(value[presetItemKey])))
    presets.splice(firstPresetLayerIndex + 1)
    return presets
}

// Receives resolved ref array and return data overrides to merge.
const getDataOverrides = (resolvedRefArray, dataQuery) => {
    const dataOverrides = {}
    dataOverrides[dataQuery] = {
        ...resolvedRefArray,
        id: dataQuery,
        values: resolvedRefArray.values.map(value => `#${value.id}`)
    }

    resolvedRefArray.values.forEach(value => {
        if (value.type === 'VariantRelation') {
            dataOverrides[value.id] = {...value, to: `#${value.to.id}`}
            dataOverrides[value.to.id] = value.to
        } else {
            dataOverrides[value.id] = value
        }
    })
    return dataOverrides
}

// Returns the item under the preset condition.
// If there is no item under the preset condition, returns the first item which isn't not under any condition.
const getItemUnderPresetCondition = (presetCondition, refArray) => {
    const getDefaultItem = () => refArray.values.find(value => value.type !== 'VariantRelation') // there should always be a default value.

    const variantRelationWithPresetCondition = refArray.values
        .find(value => value.type === 'VariantRelation' && value.variants
            .some(variantId => removeHash(variantId) === presetCondition))
    return variantRelationWithPresetCondition ? variantRelationWithPresetCondition.to : getDefaultItem()
}

// Resolves Style\Layout ref arrays with presets and return data overrides.
// "presets" is an ordered array of presets from the outer refComponent to the inner refComponent
const resolvePresets = (resolvedRefArray, dataQuery, presets, mapName, shouldResolvePresets) => {
    if (!shouldResolvePresets || !presets.length) {
        return {
            [dataQuery]: {
                ...resolvedRefArray,
                id: dataQuery,
                values: resolvedRefArray.values.map(x => `#${x.id}`)
            }
        }
    }

    const presetItemKey = mapName === 'layout_data' ? 'layout' : 'style'

    const filteredPresets = filterPresets(presets, resolvedRefArray, presetItemKey)

    _(filteredPresets).reverse().forEach(presetsArray => {
        resolvedRefArray.values = presetsArray.map(presetDataItem => {
            if (presetDataItem.type === 'PresetData') {
                const item = getItemUnderPresetCondition(presetDataItem[presetItemKey], resolvedRefArray)
                const itemId = `${dataQuery}-${item.id}`
                return {
                    ...item,
                    id: itemId
                }
            }

            const presetItem = presetDataItem.to
            const item = getItemUnderPresetCondition(presetItem[presetItemKey], resolvedRefArray)
            const itemId = `${dataQuery}-${item.id}`
            const variantRelationId = `${dataQuery}-${presetDataItem.id}`
            return {
                ...presetDataItem,
                id: variantRelationId,
                to: {
                    ...item,
                    id: itemId
                }
            }
        })
    })

    return getDataOverrides(resolvedRefArray, dataQuery)
}

const getPresetsFromRefArray = (presetsRefArray, presetsDataMap) => presetsRefArray.values.map(itemId => {
        const resolvedItem = presetsDataMap[removeHash(itemId)]
        return resolvedItem.type === 'VariantRelation' ?
            {...resolvedItem, to: presetsDataMap[removeHash(resolvedItem.to)]} :
            resolvedItem
    })

const getPresets = (presets, presetsDataMap) => presets.map(preset => {
        if (!preset) {
            return []
        }
        if (preset.type === 'RefArray') {
            return getPresetsFromRefArray(preset, presetsDataMap)
        }
        return [preset]
    })



module.exports = {
    functionLibrary: {
        resolvePresets,
        isRefArrayUnderPreset,
        getPresets
    }
}
